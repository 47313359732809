import { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"
import API from "../../api"
import HeaderComponent from "../../components/header/header.component"
import FooterComponent from "../../components/footer/footer.component"
import useScrollToTop from "../../utils/scrollToTop.util"
import { Helmet } from "react-helmet"
import CtaComponent from "../../components/cta/cta.component"
import ThreeColComponent from "../../components/three-col/three-col.component"
import TextHeroComponent from "../../components/text-hero/text-hero.component"
import TextBlockComponent from "../../components/text-block/text-block.component"
import ImageSliderComponent from "../../components/image-slider/image-slider.component"
import FlexContainerComponent from "../../components/flex-container/flex-container.component"

const ClientDemoPage = () => {
  const { Slug } = useParams()
  const [content, setContent] = useState()
  const [password, setPassword] = useState("")
  const [error, setError] = useState()
  useScrollToTop(Slug)

  const handleFetchPage = useCallback(async () => {
    if (!password || !Slug) return

    setError(false)
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))

    try {
      const res = await API.getContent(
        "client-demo-pages",
        null,
        {
          "filters[$and][0][Slug][$eq]": Slug,
          "filters[$and][1][Password][$eq]": password,
          "populate[0]": "PageContent",
          "populate[1]": "PageContent.Media",
          "populate[2]": "PageContent.Items",
          "populate[3]": "PageContent.Items.Image",
          "populate[4]": "PageContent.Items.Media",
        },
        true
      )

      // Store in session
      window.sessionStorage.setItem(
        `__dagger-client-demo-${Slug}__`,
        window.btoa(password)
      )
      setContent(res)
    } catch (e) {
      setError(true)
    } finally {
      document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING"))
    }
  }, [Slug, password])

  useEffect(() => {
    if (!content) {
      // Check if we have a saved password in session
      const sessionPw = window.sessionStorage.getItem(
        `__dagger-client-demo-${Slug}__`
      )
      if (sessionPw) {
        setPassword(window.atob(sessionPw))
        setTimeout(handleFetchPage, 250)
        return
      }

      setTimeout(
        () => document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING")),
        250
      )
    }
  }, [content, Slug, handleFetchPage])

  const getContentForComponent = (comp) => {
    switch (comp.__component) {
      default: {
        console.error("UI Component not found: ", comp.__component)
        return null
      }
      case "ui-components.dagger-cta": {
        return <CtaComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-3-col": {
        return <ThreeColComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-text-hero": {
        return <TextHeroComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-image-slider": {
        return (
          <ImageSliderComponent
            key={comp.__component + comp.id}
            {...comp}
            usePadding
          />
        )
      }
      case "ui-components.dagger-text-block": {
        return <TextBlockComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-flex-container": {
        return (
          <FlexContainerComponent key={comp.__component + comp.id} {...comp} />
        )
      }
    }
  }

  return (
    <div className="dagger-page">
      <Helmet>
        <title>{`DAGGER ${
          content?.attributes?.ClientName
            ? ` - ${content?.attributes?.ClientName}`
            : ""
        }`}</title>
        <meta
          property="og:description"
          content={content?.attributes.WelcomeCopy}
        />
        <meta property="og:site_name" content="DAGGER agency" />
        <meta property="og:title" content={content?.attributes.ClientName} />
        <meta property="og:type" content="Page" />
        <meta
          property="og:url"
          content={`${window.location.host}/${content?.attributes.Slug}`}
        />
      </Helmet>
      <HeaderComponent />
      {!content && (
        <div className="dagger-form">
          <h4>Please enter your password to access this page</h4>
          <input
            type="password"
            value={password}
            placeholder="Password..."
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            disabled={!password}
            onClick={handleFetchPage}
            className="dagger-outline-btn"
          >
            View
          </button>
          {error && (
            <div className="dagger-error-copy">
              Uh oh. Looks like that password might be incorrect. Please try
              again
            </div>
          )}
        </div>
      )}
      {password && content && (
        <>
          <div className="dagger-client-welcome">
            <h3>{content.attributes.ClientName}</h3>
            <h5>{content.attributes.WelcomeCopy}</h5>
          </div>
          {content?.attributes.PageContent?.length > 0 &&
            content.attributes.PageContent.map((c) =>
              getContentForComponent(c)
            )}
        </>
      )}
      <FooterComponent />
    </div>
  )
}

export default ClientDemoPage
