export const LOREM = (
  <>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ultrices dui sapien eget
    mi proin sed. Consectetur libero id faucibus nisl tincidunt eget. Nisl
    tincidunt eget nullam non nisi. Varius morbi enim nunc faucibus a. Libero
    enim sed faucibus turpis in eu mi bibendum. Justo eget magna fermentum
    iaculis eu non diam phasellus vestibulum. Urna duis convallis convallis
    tellus id interdum velit. Maecenas pharetra convallis posuere morbi. Platea
    dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim.
    Facilisis sed odio morbi quis commodo odio aenean. At elementum eu facilisis
    sed. Scelerisque felis imperdiet proin fermentum leo vel. Consequat interdum
    varius sit amet mattis vulputate enim nulla aliquet. Ipsum dolor sit amet
    consectetur adipiscing elit ut aliquam purus.
    <br />
    <br />
    Quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum.
    Quam id leo in vitae turpis massa. Viverra justo nec ultrices dui sapien
    eget mi proin. Tincidunt arcu non sodales neque sodales ut etiam sit amet.
    Eu non diam phasellus vestibulum lorem sed risus ultricies. Risus sed
    vulputate odio ut. Aliquam sem fringilla ut morbi tincidunt augue interdum.
    Pellentesque nec nam aliquam sem et tortor. Sit amet mattis vulputate enim
    nulla aliquet. Nec ultrices dui sapien eget mi proin. Vitae justo eget magna
    fermentum iaculis eu non diam. Ullamcorper dignissim cras tincidunt lobortis
    feugiat vivamus at augue. Turpis nunc eget lorem dolor sed viverra ipsum
    nunc. Nullam ac tortor vitae purus faucibus ornare. Libero id faucibus nisl
    tincidunt. Gravida dictum fusce ut placerat orci nulla pellentesque.
    Vulputate sapien nec sagittis aliquam. Nunc scelerisque viverra mauris in
    aliquam sem fringilla ut.
    <br />
    <br />
    Tincidunt nunc pulvinar sapien et ligula ullamcorper. Iaculis nunc sed augue
    lacus viverra vitae. Scelerisque eu ultrices vitae auctor eu augue ut. Netus
    et malesuada fames ac turpis egestas. Adipiscing elit ut aliquam purus.
    Porta non pulvinar neque laoreet suspendisse interdum consectetur libero id.
    Tempus urna et pharetra pharetra. Vulputate odio ut enim blandit volutpat
    maecenas volutpat blandit. Lacinia at quis risus sed. Purus sit amet luctus
    venenatis lectus magna fringilla urna. Quis imperdiet massa tincidunt nunc
    pulvinar. Semper eget duis at tellus at urna. Tellus mauris a diam maecenas.
    <br />
    <br />
    Quisque id diam vel quam elementum. Malesuada fames ac turpis egestas sed
    tempus urna et. Duis at consectetur lorem donec. Massa ultricies mi quis
    hendrerit dolor magna eget. Enim nulla aliquet porttitor lacus luctus
    accumsan. Consequat ac felis donec et odio pellentesque diam volutpat
    commodo. Tellus cras adipiscing enim eu turpis egestas pretium aenean.
    Ultricies mi eget mauris pharetra et ultrices. Imperdiet sed euismod nisi
    porta lorem mollis aliquam. Pharetra sit amet aliquam id diam maecenas
    ultricies mi eget. Ultrices gravida dictum fusce ut placerat orci nulla.
    Volutpat lacus laoreet non curabitur gravida. Vulputate mi sit amet mauris
    commodo quis. Nunc aliquet bibendum enim facilisis gravida neque convallis.
    <br />
    <br />
    Pellentesque habitant morbi tristique senectus et netus. Et malesuada fames
    ac turpis egestas maecenas pharetra. Duis tristique sollicitudin nibh sit
    amet commodo nulla facilisi. Leo vel fringilla est ullamcorper eget nulla.
    Vitae turpis massa sed elementum. Auctor neque vitae tempus quam
    pellentesque nec nam aliquam. Tincidunt eget nullam non nisi. Sagittis id
    consectetur purus ut faucibus pulvinar elementum integer enim. Scelerisque
    mauris pellentesque pulvinar pellentesque habitant morbi tristique. Morbi
    tincidunt ornare massa eget egestas purus viverra. In hac habitasse platea
    dictumst vestibulum rhoncus est pellentesque.`
  </>
)

export const API_TOKEN =
  "cea0aeebbe16ce67cb8474810a17b20b5f2dbb970708106cfc04e6fa0d2721e0a07c5f6eced3ca5a9ea5710bf4b3a66c6b03bbc25ffd2a492f1bc4a6c144857b8debdf3228a2ea883c4b153f55e565997ed4d2d9df666f8bda369a75e4981a2d55b897c779eff3d1e289ae2c015682de8972208a2dae1825b4170b0f468c549b"
