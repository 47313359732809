import { useNavigate } from "react-router-dom"
import SpinningLogoComponent from "../spinning-logo/spinning-logo.component"
import "./work-item.component.css"

const WorkItemComponent = (props) => {
  const { CaseStudy, AddSpinningLogo } = props

  const navigate = useNavigate()

  const handleNav = () => {
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    navigate(`/study/${CaseStudy.data.attributes.Slug}`)
  }

  return (
    <div className="dagger-single-work-item" onClick={handleNav}>
      {AddSpinningLogo && (
        <div className="dagger-single-work-item-logo">
          <SpinningLogoComponent />
        </div>
      )}
      <div className="dagger-single-work-item-content">
        <div className="dagger-item-hint">View Work</div>
        <div className="dagger-single-work-item-image">
          <img
            src={CaseStudy.data.attributes.HeroImage.data.attributes.url}
            alt={
              CaseStudy.data.attributes.HeroImage.data.attributes.alternateText
            }
          />
        </div>
        <h6>{CaseStudy.data.attributes.Client}</h6>
        <h5>{CaseStudy.data.attributes.HeroCaption}</h5>
      </div>
    </div>
  )
}

export default WorkItemComponent
