import { API_TOKEN } from "./constants"

const isLocal = window.location.href.indexOf("localhost") > -1
const _baseUrl = isLocal
  ? "http://localhost:1337"
  : "https://manage.dagger-agency.com"
const _apiUrl = _baseUrl + "/api"
const _isTestEnv =
  window.location.href.indexOf("localhost") > -1 ||
  window.location.href.indexOf("staging") > -1 ||
  window.location.href.indexOf("192.168") > -1

class API {
  static apiUrl = () => _apiUrl
  static isTest = () => _isTestEnv
  static getContent = (contentType, contentId, filter, single) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = filter ? new URLSearchParams(filter) : ""
        const theId = contentId ? `/${contentId}` : ""
        const url = `${_apiUrl}/${contentType}${theId}?${params}`
        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        }).then((r) => r.json())

        if (res.error) {
          return reject(res.error)
        }

        let data = res.data || []

        // Filter out data based on env
        if (!_isTestEnv && Array.isArray(data)) {
          data = data.filter(
            (item) => item.attributes.Environment === "Staging/Production"
          )
        }

        if (single) {
          if (data.length > 0) {
            return resolve(data[0])
          }

          return reject(false)
        }

        return resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default API
