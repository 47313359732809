import { useEffect } from "react"

const useScrollToTop = (Slug) => {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 250)
  }, [Slug])

  return null
}

export default useScrollToTop
