import { useState } from "react"
import "./multi-content-picker.component.css"
import { arrowIcon } from "../../icons"

const MultiContentPickerComponent = (props) => {
  const { Heading, Items } = props
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div className="dagger-multi-content-picker">
      <h4>{Heading}</h4>
      <div className="dagger-multi-content-picker-content">
        <div className="dagger-multi-content-picker-options">
          <ul>
            {Items?.map((i, index) => {
              return (
                <li
                  key={i.id}
                  className={selectedIndex === index ? "selected" : ""}
                  onClick={() => setSelectedIndex(index)}
                >
                  {selectedIndex === index && arrowIcon} {i.Heading}
                  {selectedIndex === index && (
                    <div
                      className="dagger-multi-content-picker-selected"
                      dangerouslySetInnerHTML={{ __html: i.Copy }}
                    />
                  )}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="dagger-multi-content-picker-image">
          <img
            src={Items[selectedIndex].Media.data.attributes.url}
            alt={Items[selectedIndex].Media.data.attributes.alternateText}
          />
        </div>
      </div>
    </div>
  )
}

export default MultiContentPickerComponent
