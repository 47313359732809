import { useEffect, useRef } from "react"
import { closeIcon } from "../../icons"
import "./video-modal.component.css"

const VideoModalComponent = (props) => {
  const { src, id, onClose, UseLandscapeAspectRatio } = props
  const containerRef = useRef()

  useEffect(() => {
    setTimeout(() => containerRef.current.classList.add("active"), 100)
  }, [])

  const handleClose = () => {
    containerRef.current.classList.remove("active")
    setTimeout(onClose, 250)
  }

  return (
    <div className="dagger-full-video-player">
      <button onClick={handleClose} className="full-video-close-btn">
        {closeIcon}
      </button>
      <div
        className={
          UseLandscapeAspectRatio
            ? "video-container landscape"
            : "video-container"
        }
        ref={containerRef}
      >
        <video
          id={`dagger-player-${id}`}
          src={src}
          autoPlay
          playsInline
          controls
        />
      </div>
    </div>
  )
}

export default VideoModalComponent
