import { useEffect, useState } from "react"
import "./positions.component.css"
import { arrowDownIcon, daggerMark } from "../../icons"
import SpinningLogoComponent from "../spinning-logo/spinning-logo.component"
import API from "../../api"
import ModalComponent from "../modal/modal.component"

const PositionsComponent = (props) => {
  const {
    Heading,
    Copy,
    AddSpinningLogo,
    ShowAboutButton,
    ShowAllWorkButton,
    AboutPageLink,
    WorkPageLink,
  } = props
  const [expanded, setExpanded] = useState({})
  const [items, setItems] = useState({})
  const [selectedJob, setSelectedJob] = useState()

  // Invert header
  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("EVENTS.INVERT_HEADER", { detail: true })
    )

    return () =>
      document.dispatchEvent(
        new CustomEvent("EVENTS.INVERT_HEADER", { detail: false })
      )
  }, [])

  // Fetch jobs
  useEffect(() => {
    const getJobs = async () => {
      try {
        const res = await API.getContent("careers")

        // Segment careers
        if (res.careers) {
          const careers = {}

          res.careers.forEach((c) => {
            if (careers[c.category]) {
              careers[c.category].jobs.push(c)
            } else {
              careers[c.category] = { category: c.category, jobs: [c] }
            }
          })

          const sortedObj = {}
          const sortedExpanded = {}
          Object.keys(careers)
            .sort((a, b) => (a.category > b.category ? 1 : -1))
            .forEach((k) => {
              sortedObj[k] = careers[k]
              sortedExpanded[k] = careers[k]
            })

          setItems(sortedObj)
          setExpanded(sortedExpanded)
        }
      } catch (e) {
        // Todo: show error
        console.log(e)
      }
    }

    getJobs()
  }, [])

  const handleToggleExpand = (c) => {
    const updated = { ...expanded }
    if (updated[c.category]) {
      delete updated[c.category]
    } else {
      updated[c.category] = c
    }

    setExpanded(updated)
  }

  const handleShowJob = (job) => {
    const applyBtn = (
      <a
        className="dagger-outline-btn"
        href={job.joblink}
        target="_blank"
        rel="noreferrer"
      >
        Apply
      </a>
    )

    const content = (
      <div className="dagger-selected-job">
        <div className="dagger-selected-job-header">
          {daggerMark}
          <div className="dagger-selected-job-tl">
            <h3>{job.title}</h3>
            <h4>
              {job.city}, {job.state}
            </h4>
            <h4>{job.category}</h4>
            {applyBtn}
          </div>
        </div>
        <div
          className="dagger-selected-job-desc"
          dangerouslySetInnerHTML={{ __html: job.description }}
        ></div>
        {applyBtn}
      </div>
    )
    setSelectedJob(content)
  }

  return (
    <div className="dagger-positions">
      {selectedJob && (
        <ModalComponent
          content={selectedJob}
          onClose={() => setSelectedJob(null)}
        />
      )}
      <div className="dagger-positions-meta">
        <h1>{Heading}</h1>
        <p>{Copy}</p>
        <div className="dagger-positions-meta-btns">
          {ShowAllWorkButton && WorkPageLink?.data && (
            <a
              href={`/${WorkPageLink.data.attributes.Slug}`}
              className="dagger-outline-btn"
            >
              All Work
            </a>
          )}
          {ShowAboutButton && AboutPageLink?.data && (
            <a
              href={`/${AboutPageLink.data.attributes.Slug}`}
              className="dagger-outline-btn"
            >
              About Us
            </a>
          )}
        </div>
      </div>
      {AddSpinningLogo && (
        <div className="dagger-positions-logo">
          <SpinningLogoComponent />{" "}
        </div>
      )}
      <div className="dagger-positions-list">
        {Object.keys(items).length < 1 && "Nothing to see here just yet!"}
        {Object.keys(items).map((k) => {
          const category = items[k]
          return (
            <div
              key={category.category}
              className={
                expanded[category.category]
                  ? "dagger-positions-group expanded"
                  : "dagger-positions-group"
              }
            >
              <div
                className="dagger-positions-group-label"
                onClick={() => handleToggleExpand(category)}
              >
                <h5>{category.category}</h5>
                {arrowDownIcon}
              </div>
              {expanded[category.category] && (
                <div className="dagger-positions-group-roles">
                  {expanded[category.category].jobs.map((j) => {
                    return (
                      <div
                        className="dagger-positions-role"
                        key={j.id}
                        onClick={() => handleShowJob(j)}
                      >
                        {j.title}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PositionsComponent
