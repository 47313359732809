import { useNavigate } from "react-router-dom"
import "./capabilities.component.css"
import { expandIcon } from "../../icons"
import { useState } from "react"

const CapabilitiesComponent = (props) => {
  const { Heading, CtaLabel, CtaPageLink, Capability } = props

  const [expanded, setExpanded] = useState({})
  const navigate = useNavigate()
  const handleCtaClick = () => {
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    navigate(`/${CtaPageLink.data.attributes.Slug}`)
  }

  const handleToggleItem = (itemId) => {
    const updated = { ...expanded }
    if (updated[itemId]) {
      delete updated[itemId]
    } else {
      updated[itemId] = true
    }

    setExpanded(updated)
  }

  return (
    <div className="dagger-capabilities">
      <h1>{Heading}</h1>
      {Capability &&
        Capability.length > 0 &&
        Capability.map((c) => {
          return (
            <div className="capability" key={c.id}>
              <div
                className="capability-heading"
                onClick={() => handleToggleItem(c.id)}
              >
                <h3>{c.Heading}</h3>
                <button
                  className={
                    expanded[c.id]
                      ? "capability-toggle-btn expanded"
                      : "capability-toggle-btn"
                  }
                >
                  {expandIcon}
                </button>
              </div>
              {expanded[c.id] && (
                <div className="capability-copy">{c.Description}</div>
              )}
            </div>
          )
        })}
      {CtaLabel && CtaPageLink && (
        <button className="dagger-outline-btn" onClick={handleCtaClick}>
          {CtaLabel}
        </button>
      )}
    </div>
  )
}

export default CapabilitiesComponent
