import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Page from "./pages/page/page.page"
import ClientDemoPage from "./pages/client-demo/client-demo.page"
import { useEffect, useState } from "react"
import API from "./api"
import CaseStudy from "./pages/case-study/case-study.page"
import LoaderComponent from "./components/loader/loader.component"
import moment from "moment"
import ExperimentsPage from "./pages/experiments.page"

const Dagger = () => {
  const [loading, setLoading] = useState(true)
  const router = createBrowserRouter([
    {
      path: "/experiments",
      element: (
        <>
          <ExperimentsPage />
        </>
      ),
    },
    {
      path: "/study/:Slug",
      element: (
        <>
          <CaseStudy />
        </>
      ),
    },
    {
      path: "/present/:Slug",
      element: (
        <>
          <ClientDemoPage />
        </>
      ),
    },
    {
      path: "/index.html",
      element: (
        <>
          <Page Slug="home" />
        </>
      ),
    },
    {
      path: "/:Slug",
      element: (
        <>
          <Page />
        </>
      ),
    },
    {
      path: "/",
      element: (
        <>
          <Page Slug="home" />
        </>
      ),
    },
  ])

  // Fetch header menu + animations on load
  useEffect(() => {
    console.log(">---- >> hello. we're DAGGER << -----<")
    const getMenuAndAnimations = async () => {
      const headerItems = await API.getContent("site-header", null, {
        "populate[0]": "PageItem",
        "populate[1]": "PageItem.PageLink",
        "populate[2]": "PageItem.CaseStudyPageLink",
        "populate[3]": "SocialOutlets",
        "populate[4]": "SocialOutlets.Media",
        "populate[5]": "TermsPrivacyPage",
      })

      let animationItems = await API.getContent(
        "dagger-logo-animations",
        null,
        {
          "populate[0]": "Media",
        }
      )

      // Randomize order of animation items -- but place any with matching date on top
      for (let i = animationItems.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[animationItems[i], animationItems[j]] = [
          animationItems[j],
          animationItems[i],
        ]
      }

      const today = moment()
      const todayArr = []
      let index = animationItems.length - 1
      while (index >= 0) {
        const a = animationItems[index]
        if (
          a.attributes.ShowOnDate &&
          moment(a.attributes.ShowOnDate).isSame(today, "day")
        ) {
          todayArr.push(a)
          animationItems.splice(index, 1)
        }

        index -= 1
      }

      animationItems = [...todayArr, ...animationItems]
        .filter((a) => a.attributes.Enabled)
        .map((a) => a.attributes.Media.data.attributes.url)

      const settings = await API.getContent("site-settings", null, {
        "populate[0]": "HomePage",
      })

      // Navigate to home
      // if (initialLoadRef.current) {
      //   if (window.location.pathname === "/") {
      //     window.location.replace(
      //       `/${settings.attributes.HomePage.data.attributes.Slug}`
      //     )
      //   }

      //   initialLoadRef.current = false
      // }

      // Write attributes to session storage
      window.sessionStorage.setItem(
        "__dagger-store__",
        JSON.stringify({
          header: headerItems.attributes,
          animations: animationItems,
          settings: settings.attributes,
        })
      )

      // Dispatch event
      document.dispatchEvent(new CustomEvent("EVENTS.HEADER_LOADED"))
    }

    getMenuAndAnimations()
  }, [])

  useEffect(() => {
    const handleStartLoading = () => {
      setLoading(true)
    }

    const handleStopLoading = () => {
      setTimeout(() => {
        setLoading(false)
      }, 1200)
    }

    document.addEventListener("EVENTS.START_LOADING", handleStartLoading)
    document.addEventListener("EVENTS.STOP_LOADING", handleStopLoading)

    return () => {
      document.removeEventListener("EVENTS.START_LOADING", handleStartLoading)
      document.removeEventListener("EVENTS.STOP_LOADING", handleStopLoading)
    }
  }, [])

  return (
    <div className="dagger-app">
      <RouterProvider router={router} />
      {loading && <LoaderComponent />}
    </div>
  )
}

export default Dagger
