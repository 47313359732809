import { useEffect } from "react"
import SpinningLogoComponent from "../spinning-logo/spinning-logo.component"
import "./loader.component.css"
import API from "../../api"

const LoaderComponent = () => {
  useEffect(() => {
    const handleStopLoader = () => {
      let top = "-100vh"
      let transform = "none"

      if (loaderItem === "flip" || loaderItem === "slideFlip") {
        top = "20vh"
        transform = "rotateX(90deg)"
      }

      if (
        loaderItem === "flip" ||
        loaderItem === "slide" ||
        loaderItem === "slideFlip"
      ) {
        window.gsap.to(".dagger-loader-slide", {
          top,
          transform,
          duration: 0.6,
          ease: "power2.inOut",
          opacity: 0,
          delay: 0.8,
        })
      }

      if (loaderItem === "tiles") {
        window.gsap.to(".dagger-loader-tile", {
          left: "100vw",
          duration: 0.5,
          opacity: 0,
          ease: "power2.inOut",
          stagger: 0.05,
          delay: 0.9,
        })

        window.gsap.to(".dagger-loader-tiles", {
          top: "100vh",
          duration: 0.3,
          opacity: 0,
          ease: "power2.inOut",
          delay: 1.3,
        })
      }
    }

    const options = ["slide", "flip", "slideFlip", "tiles"]
    let loaderItemStored = window.localStorage.getItem("__dagger-loader-pref__")

    if (!loaderItemStored) {
      loaderItemStored = API.isTest() ? "random" : "none"
    }

    const loaderItem =
      loaderItemStored === "random"
        ? options[Math.floor(Math.random() * options.length)]
        : loaderItemStored

    if (loaderItem) {
      let transitionLogo = false

      // None
      if (loaderItem === "none") {
        window.gsap.fromTo(
          ".dagger-loader-slide",
          {
            top: 0,
            opacity: 0,
            backgroundColor: "#000",
          },
          {
            opacity: 1,
            top: 0,
            duration: 0.3,
            backgroundColor: "#000",
          }
        )
      }

      // Slide
      if (loaderItem === "slide" || loaderItem === "slideFlip") {
        window.gsap.fromTo(
          ".dagger-loader-slide",
          {
            top: "100vh",
            opacity: 1,
          },
          {
            top: 0,
            duration: 0.3,
            ease: "power3.inOut",
          }
        )

        transitionLogo = true
      }

      // Flip
      if (loaderItem === "flip") {
        window.gsap.fromTo(
          ".dagger-loader-slide",
          {
            top: "10vh",
            left: "-10vw",
            opacity: 0,
            transform: "rotateY(80deg)",
          },
          {
            top: 0,
            left: 0,
            duration: 0.4,
            opacity: 1,
            ease: "power3.inOut",
            transform: "none",
          }
        )

        transitionLogo = true
      }

      // Tiles
      if (loaderItem === "tiles") {
        window.gsap.fromTo(
          ".dagger-loader-tiles",
          {
            top: "100vh",
            opacity: 0,
          },
          {
            top: 0,
            duration: 0.1,
            opacity: 1,
            ease: "power3.inOut",
          }
        )

        window.gsap.fromTo(
          ".dagger-loader-tile",
          {
            left: "-100vw",
            opacity: 0,
          },
          {
            left: 0,
            duration: 0.25,
            opacity: 1,
            ease: "power3.inOut",
            stagger: 0.05,
            delay: 0.1,
          }
        )

        transitionLogo = true
      }

      // Transition logo
      if (transitionLogo) {
        window.gsap.fromTo(
          [
            ".dagger-loader-overlay .dagger-spinning-logo svg path",
            ".dagger-loader-caption",
          ],
          {
            fill: "#e7e3da",
            color: "#e7e3da",
          },
          {
            fill: "#2c2b2c",
            color: "#2c2b2c",
            duration: 0.3,
            ease: "power3.inOut",
          }
        )

        document.addEventListener("EVENTS.STOP_LOADING", handleStopLoader)
      }
    }

    return () => {
      document.removeEventListener("EVENTS.STOP_LOADING", handleStopLoader)
    }
  }, [])

  return (
    <div className="dagger-loader-overlay">
      {<SpinningLogoComponent isLoading />}
      <div className="dagger-loader-slide" />
      <div className="dagger-loader-tiles">
        <div className="dagger-loader-tile" />
        <div className="dagger-loader-tile" />
        <div className="dagger-loader-tile" />
        <div className="dagger-loader-tile" />
        <div className="dagger-loader-tile" />
      </div>
    </div>
  )
}

export default LoaderComponent
