import { useEffect, useRef } from "react"
import { closeIcon } from "../../icons"
import "./modal.component.css"

const ModalComponent = (props) => {
  const { onClose, content, inverted } = props
  const elRef = useRef()

  useEffect(() => {
    if (!elRef.current) return

    // Add active class
    setTimeout(() => elRef.current.classList.add("active"))
  }, [])

  const handleClose = () => {
    elRef.current.classList.remove("active")
    setTimeout(onClose, 300)
  }

  return (
    <div className={inverted ? "dagger-modal inverted" : "dagger-modal"}>
      <div className="dagger-modal-content" ref={elRef}>
        <button className="dagger-close-btn" onClick={handleClose}>
          {closeIcon}
        </button>
        {content}
      </div>
    </div>
  )
}

export default ModalComponent
