import "./image-slider.component.css"
import { useEffect, useRef } from "react"
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm"
import "@glidejs/glide/dist/css/glide.core.css"
import { nextIcon, prevIcon } from "../../icons"

const ImageSliderComponent = (props) => {
  const { Items, usePadding } = props
  const glideRef = useRef()

  const createImageItem = (item) => {
    return (
      <div key={item.id} className="dagger-image-item glide__slide">
        <div className="dagger-image-item-image">
          <img
            src={`${item.attributes.url}`}
            alt={item.attributes.alternateText}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (Items?.length < 1 || glideRef.current) return

    glideRef.current = new Glide(".glide-image-slider", {
      type: "carousel",
      startAt: 0,
      perView: 2.5,
      focusAt: 0,
      gap: 40,
      peek: {
        before: 0,
        after: 150,
      },
      breakpoints: {
        300: {
          perView: 1,
          peek: 0,
        },
        600: {
          perView: 1.5,
          peek: 0,
        },
        860: {
          perView: 3,
          peek: 0,
        },
      },
    }).mount({ Controls, Breakpoints })
  }, [Items])

  return (
    <div
      className={
        usePadding ? "dagger-image-slider with-padding" : "dagger-image-slider"
      }
    >
      <div className="glide glide-image-slider">
        <div className="dagger-image-items glide__track" data-glide-el="track">
          <div className="glide__slides">
            {Items?.data?.map((i) => createImageItem(i))}
          </div>
          <div data-glide-el="controls" className="glide-controls">
            <button data-glide-dir="<">{prevIcon}</button>
            <button data-glide-dir=">">{nextIcon}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageSliderComponent
