import "./case-study-results.component.css"

const CaseStudyResultsComponent = (props) => {
  const { Heading, Results, HexColor } = props
  return (
    <div className="dagger-case-study-results-container">
      <h5 style={{ color: HexColor }}>{Heading}</h5>
      <div className="dagger-case-study-results">
        {Results?.map((p) => {
          return (
            <div className="dagger-case-study-result" key={p.id}>
              <h2>{p.Result}</h2>
              <h5 style={{ color: HexColor }}>{p.Copy}</h5>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CaseStudyResultsComponent
