import "./case-study-problem-solution.component.css"

const CaseStudyProblemSolutionComponent = (props) => {
  const { ProblemSolutions, HexColor } = props

  return (
    <div className="dagger-case-study-solutions">
      {ProblemSolutions?.map((p) => {
        return (
          <div className="dagger-case-study-solution" key={p.id}>
            <h5 style={{ color: HexColor }}>{p.Heading}</h5>
            <p>{p.Copy}</p>
          </div>
        )
      })}
    </div>
  )
}

export default CaseStudyProblemSolutionComponent
