import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { closeIcon, daggerMark, daggerText, menuIcon } from "../../icons"
import "./header.component.css"
import { Link } from "react-router-dom"
import SiteMenuComponent from "../site-menu/site-menu.component"

const HeaderComponent = (props) => {
  const { HexColor } = props
  const [showMenu, setShowMenu] = useState()
  const [items, setItems] = useState({})
  const elRef = useRef()
  const hexRef = useRef()
  const fullMenuRef = useRef()

  // Get items on header event
  useEffect(() => {
    const loadItems = () => {
      const store = window.sessionStorage.getItem("__dagger-store__")
      if (store) {
        const json = JSON.parse(store)
        setItems({ ...json.header, ...json.settings })
      }
    }

    document.addEventListener("EVENTS.HEADER_LOADED", loadItems)
    loadItems()
    return () => document.removeEventListener("EVENTS.HEADER_LOADED", loadItems)
  }, [])

  // Set hex color
  useEffect(() => {
    if (HexColor) {
      hexRef.current = HexColor
    } else {
      hexRef.current = null
    }
  }, [HexColor])

  // Setup scrolltrigger to update background on scroll
  useLayoutEffect(() => {
    const handleScrollDown = () => {
      if (elRef.current) {
        elRef.current.classList.add("activated")

        if (hexRef.current) {
          elRef.current.style.backgroundColor = hexRef.current
        }
      }
    }

    const handleScrollUp = () => {
      if (elRef.current) {
        elRef.current.classList.remove("activated")

        if (hexRef.current) {
          elRef.current.style.backgroundColor = "initial"
        }
      }
    }

    const createScrollDownTrigger = () => {
      window.ScrollTrigger.create({
        trigger: `.dagger-header`,
        start: "10% top",
        onEnter: handleScrollDown,
        onLeaveBack: handleScrollUp,
      })
    }

    createScrollDownTrigger()
  }, [])

  // Listen for invert event
  useEffect(() => {
    const handleInvert = (e) => {
      if (!elRef.current) return

      const invert = e.detail
      if (invert) {
        elRef.current.classList.add("inverted")
      } else {
        elRef.current.classList.remove("inverted")
      }
    }

    document.addEventListener("EVENTS.INVERT_HEADER", handleInvert)
    document.addEventListener("EVENTS.CLOSE_HEADER", handleCloseMenu)

    return () => {
      document.removeEventListener("EVENTS.INVERT_HEADER", handleInvert)
      document.removeEventListener("EVENTS.CLOSE_HEADER", handleCloseMenu)
    }
  }, [])

  const handleCloseMenu = () => {
    if (fullMenuRef.current) {
      fullMenuRef.current.classList.remove("active")
    }

    setTimeout(() => {
      setShowMenu(false)
    }, 250)
  }

  const handleShowMenu = () => {
    setShowMenu(true)
    setTimeout(() => {
      fullMenuRef.current.classList.add("active")
    })
  }

  const handleShowLoader = (dest) => {
    if (dest === window.location.pathname) return
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
  }

  return (
    <header className="dagger-header" ref={elRef}>
      <Link
        to={`/`}
        onClick={() => handleShowLoader(`/`)}
        className="dagger-top-level dagger-main-mark"
      >
        {daggerMark}
      </Link>
      <button className="dagger-top-level menu-btn" onClick={handleShowMenu}>
        {menuIcon}
      </button>
      {showMenu && (
        <div className="dagger-header-menu">
          <button
            className="dagger-header-menu-close-btn"
            onClick={handleCloseMenu}
          >
            {closeIcon}
          </button>
          <div className="dagger-header-menu-content" ref={fullMenuRef}>
            <div className="dagger-header-menu-content-top">
              <SiteMenuComponent items={items} />
              <div className="dagger-header-menu-content-top-extras">
                <h2>{items.ContactHeading}</h2>
                <p>
                  {items.ContactPhone}
                  <br />
                  <a href={`mailto:${items.ContactEmail}`}>
                    {items.ContactEmail}
                  </a>
                </p>
                <p>
                  {items.ContactAddressLineOne}
                  <br />
                  {items.ContactAddressLineTwo}
                  <br />
                  {items.ContactAddressLineThree}
                </p>
                <div className="dagger-header-menu-content-top-extras-bottom">
                  <a href={`mailto:${items.PressEmail}`}>Press</a>
                  <a href={`mailto:${items.NewBusinessEmail}`}>New Business</a>
                </div>
              </div>
            </div>
            <div className="dagger-header-menu-wordmark">{daggerText}</div>
          </div>
        </div>
      )}
    </header>
  )
}

export default HeaderComponent
