import { useNavigate } from "react-router-dom"
import "./work-hero.component.css"

const WorkHeroComponent = (props) => {
  const { Heading, Description, FeaturedCaseStudy } = props
  const navigate = useNavigate()

  const handleNav = () => {
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    navigate(`/study/${FeaturedCaseStudy.data.attributes.Slug}`)
  }

  return (
    <div className="dagger-work-hero">
      <div className="dagger-work-hero-meta">
        <h1>{Heading}</h1>
        <p>{Description}</p>
      </div>
      <div className="dagger-work-hero-feat" onClick={handleNav}>
        <div className="dagger-item-hint">View Work</div>
        <img
          src={FeaturedCaseStudy.data.attributes.HeroImage.data.attributes.url}
          alt={
            FeaturedCaseStudy.data.attributes.HeroImage.data.attributes
              .alternateText
          }
        />
        <h6>{FeaturedCaseStudy.data.attributes.Client}</h6>
        <h5>{FeaturedCaseStudy.data.attributes.Title}</h5>
      </div>
    </div>
  )
}

export default WorkHeroComponent
