import { Remarkable } from "remarkable"
import "./text-block.component.css"

const TextBlockComponent = (props) => {
  const { Title, Copy } = props
  const md = new Remarkable()

  return (
    <div className="dagger-text-block">
      <h6>{Title}</h6>
      {Copy && (
        <div
          className="dagger-text-block-copy"
          dangerouslySetInnerHTML={{
            __html: md.render(Copy),
          }}
        ></div>
      )}
    </div>
  )
}

export default TextBlockComponent
