import "./video-loop.component.css"

const VideoLoopComponent = (props) => {
  const { id, Media } = props

  return (
    <div className={`dagger-video-loop dagger-video-loop-${id}`}>
      <div className="dagger-video-loop-video">
        <video
          playsInline
          src={`${Media.data.attributes.url}`}
          autoPlay
          muted
          loop
        ></video>
      </div>
    </div>
  )
}

export default VideoLoopComponent
