import { daggerBadge, daggerBadgeLoading } from "../../icons"
import "./spinning-logo.component.css"

const SpinningLogoComponent = (props) => {
  return (
    <div className="dagger-spinning-logo">
      {props.isLoading ? daggerBadgeLoading : daggerBadge}
    </div>
  )
}

export default SpinningLogoComponent
