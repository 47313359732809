import { Remarkable } from "remarkable"
import SpinningLogoComponent from "../spinning-logo/spinning-logo.component"
import "./text-hero.component.css"

const TextHeroComponent = (props) => {
  const { Heading, Subheading, Copy, AddSpinningLogo } = props
  const md = new Remarkable()

  return (
    <div className="dagger-text-hero">
      <h1>{Heading}</h1>
      {Subheading && Copy && <h2>{Subheading}</h2>}
      {Subheading && !Copy && (
        <div className="dagger-text-hero-copy">{Subheading}</div>
      )}
      {Copy && (
        <div
          className="dagger-text-hero-copy"
          dangerouslySetInnerHTML={{
            __html: md.render(Copy),
          }}
        ></div>
      )}
      {AddSpinningLogo && (
        <div className="dagger-text-hero-logo">
          <SpinningLogoComponent />
        </div>
      )}
    </div>
  )
}

export default TextHeroComponent
