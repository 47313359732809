import "./three-col.component.css"

const ThreeColComponent = (props) => {
  const {
    Heading,
    ColumnOneHeading,
    ColumnOneCopy,
    ColumnTwoHeading,
    ColumnTwoCopy,
    ColumnThreeHeading,
    ColumnThreeCopy,
  } = props

  return (
    <div className="dagger-three-col-container">
      <h1>{Heading}</h1>
      <ul className="dagger-three-col">
        <li className="col">
          <h4>{ColumnOneHeading}</h4>
          <p dangerouslySetInnerHTML={{ __html: ColumnOneCopy }}></p>
        </li>
        <li className="col">
          <h4>{ColumnTwoHeading}</h4>
          <p dangerouslySetInnerHTML={{ __html: ColumnTwoCopy }}></p>
        </li>
        <li className="col">
          <h4>{ColumnThreeHeading}</h4>
          <p dangerouslySetInnerHTML={{ __html: ColumnThreeCopy }}></p>
        </li>
      </ul>
    </div>
  )
}

export default ThreeColComponent
