import "./text-image-block.component.css"
import { Remarkable } from "remarkable"

const TextImageBlockComponent = (props) => {
  const { id, Heading, Subheading, Body, Media } = props
  const md = new Remarkable()

  return (
    <div className={`dagger-text-image-block dagger-text-image-block-${id}`}>
      <h2>{Heading}</h2>
      {Subheading && (
        <h3
          dangerouslySetInnerHTML={{
            __html: md.render(Subheading),
          }}
        />
      )}
      {Body && Media && (
        <div className="dagger-text-image-block-main">
          <p
            dangerouslySetInnerHTML={{
              __html: md.render(Body),
            }}
          />
          <img
            src={`${Media.data.attributes.url}`}
            alt={Media.data.attributes.alternateText}
          />
        </div>
      )}
    </div>
  )
}

export default TextImageBlockComponent
