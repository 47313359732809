import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import API from "../../api"
import HeaderComponent from "../../components/header/header.component"
import LogoAnimation from "../../components/logo-animation/logo-animation.component"
import VideoScrollComponent from "../../components/video-scroll/video-scroll.component"
import TextScrollComponent from "../../components/text-scroll/text-scroll.component"
import CtaComponent from "../../components/cta/cta.component"
import ThreeColComponent from "../../components/three-col/three-col.component"
import MultiContentPickerComponent from "../../components/multi-content-picker/multi-content-picker.component"
import FooterComponent from "../../components/footer/footer.component"
import PeopleGridComponent from "../../components/people-grid/people-grid.component"
import CapabilitiesComponent from "../../components/capabilities/capabilities.component"
import AwardsComponent from "../../components/awards/awards.component"
import WorkSliderComponent from "../../components/work-slider/work-slider.component"
import ClientSliderComponent from "../../components/client-slider/client-slider.component"
import IgFeedComponent from "../../components/ig-feed/ig-feed.component"
import TextHeroComponent from "../../components/text-hero/text-hero.component"
import WorkItemComponent from "../../components/work-item/work-item.component"
import WorkHeroComponent from "../../components/work-hero/work-hero.component"
import PositionsComponent from "../../components/positions/positions.component"
import useScrollToTop from "../../utils/scrollToTop.util"
import CaseStudyMediaContainerComponent from "../../components/case-study-media-container/case-study-media-container.component"
import ImageSliderComponent from "../../components/image-slider/image-slider.component"
import Helmet from "react-helmet"
import TextBlockComponent from "../../components/text-block/text-block.component"
import { daggerMark } from "../../icons"
import FlexContainerComponent from "../../components/flex-container/flex-container.component"
import VideoLoopComponent from "../../components/video-loop/video-loop.component"
import TextImageBlockComponent from "../../components/text-image-block/text-image-block.component"
import PeopleListComponent from "../../components/people-list/people-list.component"
import PressSliderComponent from "../../components/press-slider/press-slider.component"

const Page = (props) => {
  const { Slug: urlSlug } = useParams()
  const { Slug: propSlug } = props
  const Slug = propSlug || urlSlug
  const [content, setContent] = useState()
  const [error, setError] = useState()
  useScrollToTop(Slug)

  useEffect(() => {
    if (!Slug) return

    const getPage = async () => {
      try {
        document.dispatchEvent(new CustomEvent("EVENTS.CLOSE_HEADER"))
        const res = await API.getContent(
          "pages",
          null,
          {
            "filters[Slug][$eq]": Slug,
            "populate[0]": "PageContent",
            "populate[1]": "PageContent.Media",
            "populate[2]": "PageContent.Items",
            "populate[3]": "PageContent.Items.Image",
            "populate[4]": "PageContent.Capability",
            "populate[5]": "PageContent.CtaPageLink",
            "populate[6]": "PageContent.Awards",
            "populate[7]": "PageContent.Awards.Media",
            "populate[8]": "PageContent.Items.CaseStudy",
            "populate[9]": "PageContent.Items.CaseStudy.HeroImage",
            "populate[10]": "PageContent.Items.Media",
            "populate[11]": "PageContent.CaseStudy",
            "populate[12]": "PageContent.CaseStudy.HeroImage",
            "populate[13]": "PageContent.FeaturedCaseStudy",
            "populate[14]": "PageContent.FeaturedCaseStudy.HeroImage",
            "populate[15]": "PageContent.PageLink",
            "populate[16]": "PageContent.WorkPageLink",
            "populate[17]": "PageContent.AboutPageLink",
            "populate[18]": "PageContent.MediaShort",
            "populate[19]": "PageContent.CaseStudyMedia.Media",
            "populate[20]": "MetaImage.Media",
            "populate[21]": "PageContent.People.Media",
          },
          true
        )

        if (res) {
          setContent(res)
          setTimeout(
            () =>
              document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING")),
            250
          )
        } else {
          setError(true)
          document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING"))
        }
      } catch (e) {
        setError(true)
        document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING"))
      }
    }

    getPage()
  }, [Slug])

  const getContentForComponent = (comp) => {
    switch (comp.__component) {
      default: {
        console.error("UI Component not found: ", comp.__component)
        return null
      }
      case "ui-components.dagger-logo-animation": {
        return <LogoAnimation key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-video-player": {
        return (
          <VideoScrollComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-text-scroll": {
        return (
          <TextScrollComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-cta": {
        return <CtaComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-3-col": {
        return <ThreeColComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-multi-content-picker": {
        return (
          <MultiContentPickerComponent
            key={comp.__component + comp.id}
            {...comp}
          />
        )
      }
      case "ui-components.dagger-leadership-grid": {
        return (
          <PeopleGridComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-capabilities": {
        return (
          <CapabilitiesComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-awards": {
        return <AwardsComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-work-slider": {
        return (
          <WorkSliderComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-client-slider": {
        return (
          <ClientSliderComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-ig-feed": {
        return <IgFeedComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-text-hero": {
        return <TextHeroComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-work-item": {
        return <WorkItemComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-work-hero": {
        return <WorkHeroComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-positions": {
        return <PositionsComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-case-study-media-container": {
        return (
          <CaseStudyMediaContainerComponent
            key={comp.__component + comp.id}
            {...comp}
            HexColor={content.attributes.HexColor}
          />
        )
      }
      case "ui-components.dagger-image-slider": {
        return (
          <ImageSliderComponent
            key={comp.__component + comp.id}
            {...comp}
            usePadding
          />
        )
      }
      case "ui-components.dagger-text-block": {
        return <TextBlockComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-flex-container": {
        return (
          <FlexContainerComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-video-loop": {
        return <VideoLoopComponent key={comp.__component + comp.id} {...comp} />
      }
      case "ui-components.dagger-text-image-block": {
        return (
          <TextImageBlockComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-people-list": {
        return (
          <PeopleListComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-press-slider": {
        return (
          <PressSliderComponent key={comp.__component + comp.id} {...comp} />
        )
      }
    }
  }

  // Get loader selection
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const loaderParam = params.get("loader")
    if (loaderParam) {
      window.localStorage.setItem("__dagger-loader-pref__", loaderParam)
    }
  }, [])

  return (
    <div className="dagger-page">
      <Helmet>
        <title>{`DAGGER ${
          content?.attributes?.Title ? ` - ${content?.attributes?.Title}` : ""
        }`}</title>
        <meta
          property="og:description"
          content={content?.attributes.MetaDescription}
        />
        <meta property="og:site_name" content="DAGGER agency" />
        <meta property="og:title" content={content?.attributes.Title} />
        <meta property="og:type" content={content?.attributes.MetaPageType} />
        <meta
          property="og:image"
          content={
            content?.attributes.MetaImage?.data?.attributes?.url
              ? content.attributes.MetaImage.data.attributes.url
              : content?.attributes.MetaImageUrl
          }
        />
        <meta
          property="og:url"
          content={`${window.location.host}/${content?.attributes.Slug}`}
        />
      </Helmet>
      {content?.attributes.IncludeHeader && <HeaderComponent />}
      {content?.attributes.PageContent?.length > 0 &&
        content.attributes.PageContent.map((c) => getContentForComponent(c))}
      {content?.attributes.IncludeFooter && <FooterComponent />}

      {/* Error State */}
      {error && (
        <div className="dagger-error">
          {daggerMark}
          <h6>Uh oh.</h6>
          <p>Looks like you took a wrong turn somewhere.</p>
          <a href="/" className="dagger-outline-btn">
            Back to home
          </a>
        </div>
      )}
    </div>
  )
}

export default Page
