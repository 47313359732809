import { useLayoutEffect, useRef, useState } from "react"
import "./video-scroll.component.css"
import { playIcon } from "../../icons"
import useCheckForMobileHook from "../../hooks/useCheckForMobileHook"
// import VideoModalComponent from "../video-modal/video-modal.component"

const VideoScrollComponent = (props) => {
  const { id, Media, MediaShort, ExpandOnScroll } = props
  // const [showModal, setShowModal] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoElRef = useRef()
  const playIconRef = useRef()
  const isMobile = useCheckForMobileHook()

  useLayoutEffect(() => {
    if (id && ExpandOnScroll && !isMobile) {
      console.log("create video scroll trigger")

      // Setup scroll trigger
      window.gsap
        .timeline({
          scrollTrigger: {
            trigger: `.dagger-video-${id}`,
            scrub: 2,
            start: "-50% top",
            end: "+=30%",
          },
        })
        .from(".dagger-video-scroll-video", {
          scale: 0.75,
          ease: "none",
        })
    }
  }, [id, ExpandOnScroll, isMobile])

  if (!Media) {
    return null
  }

  // const handleShowVideo = () => {
  //   // Pause current
  //   videoElRef.current.pause()

  //   // Open full
  //   setShowModal(true)
  // }

  // const handleCloseVideo = () => {
  //   // Close full
  //   setShowModal(false)

  //   // Resume current
  //   videoElRef.current.play()
  // }

  const handleShowVideo = () => {
    if (isPlaying) return

    // Pause current
    videoElRef.current.pause()

    // Swap src
    setIsPlaying(true)
    videoElRef.current.src = Media.data.attributes.url
    videoElRef.current.muted = false
    videoElRef.current.controls = true
    videoElRef.current.loop = false
  }

  return (
    <div className={`dagger-video-scroll dagger-video-${id}`}>
      {/* <h1>{Heading}</h1> */}
      <div className="dagger-video-scroll-video">
        <video
          ref={videoElRef}
          playsInline
          src={`${MediaShort.data.attributes.url}`}
          autoPlay
          muted
          loop
          onClick={handleShowVideo}
        ></video>
        {!isPlaying && (
          <div className="video-play-icon" ref={playIconRef}>
            {playIcon}
          </div>
        )}
      </div>
      {/* {showModal && (
        <VideoModalComponent
          id={id}
          src={Media.data.attributes.url}
          onClose={handleCloseVideo}
        />
      )} */}
    </div>
  )
}

export default VideoScrollComponent
