import "./awards.component.css"

const AwardsComponent = (props) => {
  const { Awards } = props
  return (
    <div className="dagger-awards">
      {Awards.map((a) => {
        return (
          <div className="dagger-award" key={a.id}>
            <img src={`${a.Media.data.attributes.url}`} alt={a.Subheading} />
            <h5>{a.Subheading}</h5>
          </div>
        )
      })}
    </div>
  )
}

export default AwardsComponent
