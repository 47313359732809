import "./client-slider.component.css"
import Marquee from "react-fast-marquee"
import useCheckForMobileHook from "../../hooks/useCheckForMobileHook"

const ClientSliderComponent = (props) => {
  const { Heading, Background, Items } = props
  const isMobile = useCheckForMobileHook()

  const createClientItem = (item) => {
    let padding = 0
    if (item.PaddingPercentage) {
      const amt = isMobile
        ? item.PaddingPercentage * 0.2
        : item.PaddingPercentage
      padding = `${amt}%`
    }

    return (
      <div key={item.id} className="dagger-client-item">
        <img
          style={{ padding }}
          src={`${item.Media.data.attributes.url}`}
          alt={item.Media.data.attributes.alternateText}
        />
      </div>
    )
  }

  const containerClass =
    Background && Background === "White"
      ? "dagger-client-slider bg-white"
      : "dagger-client-slider"

  return (
    <div className={containerClass}>
      <h6>{Heading}</h6>
      <Marquee className="dagger-client-items" speed={isMobile ? 37.5 : 25}>
        {Items?.map((i) => createClientItem(i))}
      </Marquee>
    </div>
  )
}

export default ClientSliderComponent
