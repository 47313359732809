import { useEffect, useState } from "react"
import HeaderComponent from "../components/header/header.component"
import FooterComponent from "../components/footer/footer.component"
import useScrollToTop from "../utils/scrollToTop.util"
import "./experiments.page.css"

const ExperimentsPage = () => {
  const [loader, setLoader] = useState()
  useScrollToTop("experiments")

  useEffect(() => {
    setTimeout(
      () => document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING")),
      250
    )
  }, [])

  // Get loader selection
  useEffect(() => {
    const loaderItem = window.localStorage.getItem("__dagger-loader-pref__")
    if (loaderItem) {
      setLoader(loaderItem)
    }
  }, [])

  const options = ["textOnly", "slide", "flip", "slideFlip", "tiles", "random"]

  const handleSelectLoader = (loaderType) => {
    setLoader(loaderType)
    window.localStorage.setItem("__dagger-loader-pref__", loaderType)
  }

  return (
    <div className="dagger-page">
      <HeaderComponent />
      <div className="dagger-experiments-page">
        <h2>Experiments</h2>
        <h6>Select a loader style to persist throughout the web experience</h6>
        <ul>
          {options.map((o, index) => {
            return (
              <li
                key={index}
                className={loader === o ? "selected" : ""}
                onClick={() => handleSelectLoader(o)}
              >
                <h6>{o}</h6>
              </li>
            )
          })}
        </ul>
      </div>

      <FooterComponent />
    </div>
  )
}

export default ExperimentsPage
