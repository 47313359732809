import { useEffect, useState } from "react"
import { daggerText } from "../../icons"
import "./footer.component.css"
import useCheckForMobileHook from "../../hooks/useCheckForMobileHook"
import SiteMenuComponent from "../site-menu/site-menu.component"

const FooterComponent = () => {
  const [items, setItems] = useState({})
  const isMobile = useCheckForMobileHook()

  // Get items on header event
  useEffect(() => {
    const loadItems = () => {
      const store = window.sessionStorage.getItem("__dagger-store__")
      if (store) {
        setItems(JSON.parse(store).header)
      }
    }

    document.addEventListener("EVENTS.HEADER_LOADED", loadItems)
    loadItems()
    return () => document.removeEventListener("EVENTS.HEADER_LOADED", loadItems)
  }, [])

  return (
    <footer className="dagger-footer">
      <div className="dagger-footer-content">
        <div className="dagger-footer-content-top">
          <div className="dagger-footer-content-top-menu">
            <SiteMenuComponent items={items} />
            {isMobile && items.SocialOutlets?.length > 0 && (
              <div className="dagger-footer-content-top-extras-bottom mobile-only">
                <h2>{items.SocialHeading}</h2>
                <div className="dagger-footer-content-top-extras-bottom-social">
                  {items.SocialOutlets.map((s) => {
                    return (
                      <a
                        key={s.id}
                        href={s.LinkUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={s.Media.data.attributes.url} alt={s.Name} />
                      </a>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="dagger-footer-content-top-extra-group">
            <div className="dagger-footer-content-top-extras">
              <h2>{items.AddressHeading}</h2>
              {items.ContactAddressLineOne}
              <br />
              {items.ContactAddressLineTwo}
              <br />
              {items.ContactAddressLineThree}
              {items.SocialOutlets?.length > 0 && (
                <div className="dagger-footer-content-top-extras-bottom desktop-only">
                  <h2>{items.SocialHeading}</h2>
                  <div className="dagger-footer-content-top-extras-bottom-social">
                    {items.SocialOutlets.map((s) => {
                      return (
                        <a
                          key={s.id}
                          href={s.LinkUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={s.Media.data.attributes.url} alt={s.Name} />
                        </a>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="dagger-footer-content-top-extras">
              <h2>{items.ContactHeading}</h2>
              {items.ContactPhone}
              <br />
              <a href={`mailto:${items.ContactEmail}`}>{items.ContactEmail}</a>
              <p>
                {items.PressCaption}:
                {items.PressName && (
                  <>
                    <br />
                    {items.PressName}
                  </>
                )}
                <br />
                <a href={`mailto:${items.PressEmail}`}>{items.PressEmail}</a>
              </p>
              <p>
                {items.NewBusinessCaption}:
                {items.NewBusinessName && (
                  <>
                    <br />
                    {items.NewBusinessName}
                  </>
                )}
                {items.NewBusinessTitle && (
                  <>
                    <br />
                    {items.NewBusinessTitle}
                  </>
                )}
                <br />
                <a href={`mailto:${items.NewBusinessEmail}`}>
                  {items.NewBusinessEmail}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="dagger-footer-wordmark">{daggerText}</div>
      </div>
      <div className="dagger-footer-legal">
        &copy; {items.CopyrightText}
        <div className="dagger-footer-legal-links">
          <a href={`/${items.TermsPrivacyPage?.data.attributes.Slug}`}>
            TERMS + PRIVACY POLICY
          </a>
          {/* <a target="_blank" rel="noreferrer" href={items.PlanIssuerLink}>
            PLAN + ISSUER PRICE TRANSPARENCY
          </a> */}
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
