import "./cta.component.css"
import { useNavigate } from "react-router-dom"

const CtaComponent = (props) => {
  const { Heading, Copy, ButtonLabel, Media, PageLink, ExternalLink } = props
  const navigate = useNavigate()

  const getBackground = () => {
    if (!Media.data) {
      return null
    }

    return {
      backgroundImage: `url(${Media.data.attributes.url})`,
    }
  }

  const handleNav = () => {
    if (ExternalLink) {
      window.location.href = ExternalLink
      return
    }

    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    navigate(`/${PageLink.data.attributes.Slug}`)
  }

  return (
    <div className="dagger-cta" style={getBackground()}>
      <h2>{Heading}</h2>
      <div className="dagger-cta-desc">
        {Copy}
        {ButtonLabel && (PageLink?.data || ExternalLink) && (
          <button className="dagger-outline-btn" onClick={handleNav}>
            {ButtonLabel}
          </button>
        )}
      </div>
    </div>
  )
}

export default CtaComponent
