import "./work-slider.component.css"
import { useEffect, useRef } from "react"
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm"
import "@glidejs/glide/dist/css/glide.core.css"
import { nextIcon, prevIcon } from "../../icons"

const WorkSliderComponent = (props) => {
  const { Heading, Items, CtaButtonLabel, PageLink } = props
  const glideRef = useRef()

  const handleNav = (path, slug) => {
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    let slugPath = path ? `/${path}/` : "/"
    slugPath += slug
    window.location.href = slugPath
  }

  const createWorkItem = (item) => {
    return (
      <div
        key={item.id}
        data-item-id={item.id}
        className="dagger-work-item glide__slide"
      >
        <div className="dagger-item-hint">View Work</div>
        <div className="dagger-work-item-image" data-item-id={item.id}>
          <img
            src={`${item.CaseStudy.data.attributes.HeroImage.data.attributes.url}`}
            alt={item.CaseStudy.data.attributes.HeroCaption}
          />
        </div>
        <h6>{item.CaseStudy.data.attributes.Client}</h6>
        {item.CaseStudy.data.attributes.HeroCaption && (
          <h5>{item.CaseStudy.data.attributes.HeroCaption}</h5>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (Items?.length < 1 || glideRef.current) return

    glideRef.current = new Glide(".glide-work-slider", {
      type: "carousel",
      startAt: 0,
      perView: 2,
      focusAt: 0,
      peek: {
        before: 0,
        after: 150,
      },
      breakpoints: {
        800: {
          perView: 1,
          peek: 0,
        },
      },
    }).mount({ Controls, Breakpoints })
  }, [Items])

  const handleItemClick = (e) => {
    if (e.target?.parentElement) {
      const itemId = e.target.parentElement.getAttribute("data-item-id")
      const item = Items.find((i) => i.id.toString() === itemId.toString())
      if (item) {
        handleNav("study", item.CaseStudy.data.attributes.Slug)
      }
    }
  }

  return (
    <div className="dagger-work-slider">
      <h6>{Heading}</h6>
      <div className="glide glide-work-slider">
        <div className="dagger-work-items glide__track" data-glide-el="track">
          <div className="glide__slides" onClick={handleItemClick}>
            {Items?.map((i, ind) => createWorkItem(i, ind))}
          </div>
          <div data-glide-el="controls" className="glide-controls">
            <button data-glide-dir="<">{prevIcon}</button>
            <button data-glide-dir=">">{nextIcon}</button>
          </div>
        </div>
      </div>
      {CtaButtonLabel && PageLink?.data && (
        <button
          className="dagger-outline-btn"
          onClick={() => handleNav(null, PageLink.data.attributes.Slug)}
        >
          {CtaButtonLabel}
        </button>
      )}
    </div>
  )
}

export default WorkSliderComponent
