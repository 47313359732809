import { useEffect, useState } from "react"
import API from "../../api"
import "./people-grid.component.css"
import ModalComponent from "../modal/modal.component"
import { Remarkable } from "remarkable"
import { arrowIcon } from "../../icons"

const PeopleGridComponent = (props) => {
  const { Heading } = props
  const [people, setPeople] = useState([])
  const [selectedPerson, setSelectedPerson] = useState()

  // Fetch people
  useEffect(() => {
    const getPeople = async () => {
      try {
        const res = await API.getContent("leadership-team-members", null, {
          "populate[0]": "Headshot",
        })
        setPeople(
          res.sort((a, b) =>
            a.attributes?.OrderInGrid > b.attributes?.OrderInGrid ? 1 : -1
          )
        )
      } catch (e) {
        // todo handle error
      }
    }

    getPeople()
  }, [])

  const getSelectedPersonContent = () => {
    const md = new Remarkable()
    return (
      <div className="dagger-selected-person">
        <div className="dagger-selected-left">
          <h2>{selectedPerson.attributes.FullName}</h2>
          <h5>{selectedPerson.attributes.Title}</h5>
          <img
            src={`${selectedPerson.attributes.Headshot.data.attributes.url}`}
            alt={selectedPerson.attributes.FullName}
          />
        </div>
        <div className="dagger-selected-right">
          <div
            className="dagger-selected-right-bio"
            dangerouslySetInnerHTML={{
              __html: md.render(selectedPerson.attributes.Bio),
            }}
          ></div>
        </div>
      </div>
    )
  }

  return (
    <div className="dagger-people-grid">
      {selectedPerson && (
        <ModalComponent
          content={getSelectedPersonContent()}
          onClose={() => setSelectedPerson(false)}
        />
      )}
      <h1>{Heading}</h1>
      <ul>
        {people?.map((p) => {
          return (
            <li key={p.id} onClick={() => setSelectedPerson(p)}>
              <div className="dagger-person-image">
                <img
                  src={`${p.attributes?.Headshot?.data?.attributes.url}`}
                  alt={p.attributes?.FullName}
                />
              </div>
              <h5>
                {p.attributes?.FullName} {arrowIcon}
              </h5>
              <h6>{p.attributes?.Title}</h6>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default PeopleGridComponent
