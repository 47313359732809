import "./flex-container.component.css"

const FlexContainerComponent = (props) => {
  const { Heading, Items } = props
  return (
    <div className="dagger-flex-container">
      {Heading && <h6>{Heading}</h6>}
      {Items && Items.length > 0 && (
        <div className="dagger-flex-items">
          {Items.map((i) => {
            return (
              <div className="dagger-flex-item" key={i.id}>
                {i.Copy && (
                  <div className="dagger-flex-item-copy">{i.Copy}</div>
                )}
                {!i.Copy && i.Media?.data && (
                  <div className="dagger-flex-item-image">
                    <img
                      src={i.Media.data.attributes.url}
                      alt={i.Media.data.attributes.alternateText}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default FlexContainerComponent
