export const daggerMark = (
  <svg viewBox="0 0 80 80">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <g id="Layer_2-2-2" data-name="Layer 2-2">
              <g id="Layer_1-2-2-2" data-name="Layer 1-2-2">
                <path d="M40,0A40,40,0,1,0,80,40h0A40,40,0,0,0,40,0Zm0,76.14A36.16,36.16,0,1,1,76.16,40h0A36.16,36.16,0,0,1,40,76.14Z" />
                <path d="M47.1,18H23.62V62H47.1L67.44,40Zm-1.52,3.55,15.5,16.75H55L40.23,21.53ZM35.54,40V33.5L41.21,40l-5.67,6.5Zm0-18.49L50.33,38.28h-6L29.65,21.53ZM27.1,40V23.82l5,5.69v21l-5,5.69Zm2.57,18.51L44.34,41.78h6L35.52,58.53Zm15.93,0H40.23L55,41.78h6Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const daggerText = (
  <svg viewBox="0 0 872 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.549 2.65283H0V128.281H50.549C87.856 128.281 115.165 101.621 115.165 65.5148C115.165 29.4988 87.856 2.65283 50.549 2.65283ZM50.269 110.694H19.442V20.0618H50.269C77.023 20.0618 94.8 40.1508 94.8 65.5138C94.8 90.9768 77.023 110.694 50.269 110.694Z"
      fill="white"
    />
    <path
      d="M645.119 80.0529H705.941V62.3699H645.119V20.2459H717.933V2.56494H625.678V128.28H719.878V110.599H645.119V80.0529Z"
      fill="white"
    />
    <path
      d="M841.961 76.9682C857.261 71.3542 867 58.5242 867 41.2582C867 17.4582 850.151 2.6582 824.23 2.6582H766.044V128.281H785.484V79.8672H821.524L849.409 128.281H871.999L841.961 76.9682ZM823.304 62.5552H785.484V20.1502H823.304C838.766 20.1502 846.725 28.4822 846.725 41.2502C846.725 53.7422 838.766 62.5502 823.304 62.5502"
      fill="white"
    />
    <path
      d="M212.645 2.65381H193.89L139.406 128.28H160.232L171.304 102.08H235.23L246.305 128.28H267.129L212.645 2.65381ZM178.153 85.8798L203.268 26.4608L228.382 85.8798H178.153Z"
      fill="white"
    />
    <path
      d="M417.053 62.555H373.214L356.914 80.14H396.814C394.314 97.179 380.614 111.707 356.451 111.707C330.53 111.707 311.645 92.36 311.645 65.419C311.645 38.573 330.159 19.137 355.988 19.137C372.482 19.137 384.115 25.842 394.448 39.6C394.451 39.603 394.454 39.6053 394.457 39.607C394.461 39.6086 394.464 39.6094 394.468 39.6094C394.472 39.6094 394.476 39.6086 394.479 39.607C394.483 39.6053 394.486 39.603 394.488 39.6L407.257 25.82C395.39 10.165 377.346 0.707031 355.988 0.707031C319.605 0.707031 291.37 28.758 291.37 65.419C291.37 102.175 319.975 130.131 356.451 130.131C394.963 130.131 417.365 102.545 417.365 69.868C417.365 68.231 417.053 62.555 417.053 62.555Z"
      fill="white"
    />
    <path
      d="M578.025 62.555H534.186L517.886 80.141H557.786C555.286 97.179 541.586 111.708 517.424 111.708C491.503 111.708 472.618 92.361 472.618 65.42C472.618 38.574 491.132 19.138 516.961 19.138C533.456 19.138 545.088 25.843 555.421 39.601C555.423 39.604 555.426 39.6063 555.43 39.608C555.433 39.6096 555.437 39.6104 555.441 39.6104C555.445 39.6104 555.448 39.6096 555.452 39.608C555.455 39.6063 555.458 39.604 555.461 39.601L568.23 25.821C556.362 10.165 538.318 0.707031 516.96 0.707031C480.576 0.707031 452.342 28.758 452.342 65.419C452.342 102.175 480.947 130.131 517.423 130.131C555.935 130.131 578.337 102.545 578.337 69.868C578.337 68.231 578.025 62.555 578.025 62.555Z"
      fill="white"
    />
  </svg>
)

export const menuIcon = (
  <svg
    width="49"
    height="17"
    viewBox="0 0 49 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1H49" stroke="white" strokeWidth="2" />
    <path d="M0 16H49" stroke="white" strokeWidth="2" />
  </svg>
)

export const closeIcon = (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.35205 35.649L36.0003 1.00082" stroke="black" strokeWidth="2" />
    <path d="M1.35205 1L36.0003 35.6482" stroke="black" strokeWidth="2" />
  </svg>
)

export const igIcon = (
  <svg width="26" height="28" viewBox="0 0 26 28" fill="none">
    <path
      d="M19.456 0.362061H6.48298C4.73234 0.396753 3.06687 1.12412 1.85163 2.38472C0.636385 3.64533 -0.0294827 5.33633 -2.2084e-05 7.08706V20.6561C-0.0297503 22.407 0.635998 24.0982 1.85127 25.359C3.06654 26.6199 4.73216 27.3474 6.48298 27.3821H19.456C21.2057 27.3474 22.8704 26.6207 24.0855 25.3612C25.3006 24.1016 25.9671 22.4119 25.939 20.6621V7.08706C25.9684 5.33633 25.3026 3.64533 24.0873 2.38472C22.8721 1.12412 21.2066 0.396753 19.456 0.362061ZM6.48298 2.85306H19.456C20.5583 2.87487 21.607 3.33297 22.372 4.12687C23.137 4.92078 23.556 5.98569 23.537 7.08806V9.40306H16.666C15.6367 8.63491 14.3868 8.21991 13.1025 8.21991C11.8182 8.21991 10.5682 8.63491 9.53898 9.40306H2.39998V7.08706C2.3815 5.9846 2.80093 4.9198 3.56632 4.12611C4.3317 3.33242 5.38058 2.87461 6.48298 2.85306ZM15.659 14.6071C15.6405 15.1085 15.475 15.5935 15.183 16.0016C14.891 16.4097 14.4854 16.7229 14.0167 16.9022C13.5481 17.0815 13.037 17.119 12.5472 17.01C12.0573 16.901 11.6104 16.6504 11.2619 16.2893C10.9135 15.9282 10.679 15.4726 10.5875 14.9791C10.4961 14.4857 10.5518 13.9763 10.7477 13.5144C10.9436 13.0524 11.2711 12.6582 11.6894 12.381C12.1076 12.1037 12.5982 11.9556 13.1 11.9551C13.7906 11.9685 14.4476 12.2552 14.9272 12.7523C15.4067 13.2494 15.6695 13.9164 15.658 14.6071H15.659ZM19.459 24.8911H6.48298C5.38031 24.8698 4.33113 24.4119 3.56566 23.6179C2.80019 22.824 2.38097 21.7588 2.39998 20.6561V11.8921H7.52998C7.14352 12.7446 6.94405 13.67 6.94498 14.6061C6.91712 16.2684 7.54926 17.874 8.70294 19.0712C9.85661 20.2683 11.4377 20.9594 13.1 20.9931C14.7626 20.9599 16.3442 20.2691 17.4983 19.0719C18.6524 17.8747 19.2848 16.2687 19.257 14.6061C19.2579 13.67 19.0584 12.7446 18.672 11.8921H23.536V20.6561C23.5547 21.7582 23.1358 22.8228 22.3711 23.6166C21.6063 24.4104 20.558 24.8687 19.456 24.8911H19.459Z"
      fill="white"
    />
  </svg>
)

export const twitterIcon = (
  <svg width="33" height="27" viewBox="0 0 33 27" fill="none">
    <path
      d="M32.226 3.43706C31.0523 3.96729 29.8046 4.31562 28.526 4.47006C29.8776 3.64261 30.8839 2.35389 31.359 0.842061C30.0979 1.60478 28.714 2.14309 27.269 2.43306C26.6716 1.78105 25.9453 1.26028 25.136 0.903776C24.3268 0.547267 23.4523 0.362796 22.568 0.362061C21.7144 0.369773 20.8707 0.545549 20.085 0.879351C19.2993 1.21315 18.5871 1.69844 17.989 2.30751C17.3909 2.91657 16.9186 3.63749 16.5991 4.42909C16.2797 5.22068 16.1192 6.06746 16.127 6.92106C16.1266 7.42407 16.1827 7.92553 16.294 8.41606C13.722 8.27935 11.2084 7.59759 8.91979 6.41596C6.63118 5.23433 4.61983 3.57982 3.01901 1.56206C2.1951 3.00623 1.94262 4.70684 2.31141 6.32809C2.6802 7.94934 3.64347 9.37339 5.01101 10.3191C3.98656 10.2855 2.98557 10.0038 2.09401 9.49806V9.58006C2.08422 11.0829 2.59276 12.5431 3.53395 13.7147C4.47514 14.8863 5.79143 15.6977 7.26101 16.0121C6.31332 16.2754 5.31727 16.3141 4.35201 16.1251C4.75135 17.4189 5.54701 18.5547 6.62663 19.372C7.70626 20.1893 9.01532 20.6468 10.369 20.6801C9.0407 21.7454 7.51285 22.5345 5.87523 23.0009C4.23762 23.4673 2.52329 23.6017 0.833008 23.3961C3.76589 25.3201 7.19737 26.3444 10.705 26.3431C22.553 26.3431 29.033 16.3491 29.033 7.68106C29.033 7.39639 29.027 7.11339 29.015 6.83206C30.2796 5.90039 31.368 4.75074 32.229 3.43706H32.226Z"
      fill="white"
    />
  </svg>
)

export const arrowIcon = (
  <svg width="19" height="13" viewBox="0 0 19 13" fill="none">
    <path d="M16.698 6.23975H0" stroke="#F4FF7D" strokeWidth="2" />
    <path
      d="M12.1357 0.926758L17.0487 6.23976L12.1357 11.5528"
      stroke="#F4FF7D"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export const daggerBadge = (
  <svg viewBox="0 0 308.85 308.19">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M152.36,222.72l-.76.86h23.74l59.78-64.65H208.65ZM228,162l-54,58.44H158.49L210.06,162Z"
        />
        <path
          className="cls-1"
          d="M181.1,71.93l-.15-.17H92.42V236.39H181l75.8-82,.32-.35ZM95.53,233.28V74.87h84.06l73.25,79.2-73.25,79.21Z"
        />
        <path
          className="cls-1"
          d="M208.65,149.22h26.47L175.49,84.74l-.15-.17H151.6l56.59,64.12ZM174,87.68l54,58.43h-18L158.49,87.68Z"
        />
        <path
          className="cls-1"
          d="M169.4,149.22h26.1L138.91,85.1l-.46-.53H112.79l56.14,64.12ZM137,87.68l51.57,58.43h-17.8L119.64,87.68Z"
        />
        <path
          className="cls-1"
          d="M195.5,158.93H169.4l-56.61,64.65h25.66l56.29-63.79Zm-75.86,61.54L170.81,162h17.8L137,220.47Z"
        />
        <path
          className="cls-1"
          d="M160.45,154.41l.3-.34-24.46-28v56Zm-3.83-.34-17.22,19.7v-39.4Z"
        />
        <path
          className="cls-1"
          d="M126.58,193.15V115L105.23,90.57v127l21.22-24.28ZM108.34,209.3V98.85l15.14,17.31V192Z"
        />
        <g className="dagger-badge-circle-text">
          <path
            className="cls-1"
            d="M82.54,17.37l-7.68,4,10,19.1,7.68-4c5.68-3,7.72-9.17,4.85-14.66S88.21,14.41,82.54,17.37Zm8.52,16.45-4.69,2.44L79.19,22.48,83.88,20a7.61,7.61,0,0,1,2.9-.9,7.53,7.53,0,0,1,3,.34,7.51,7.51,0,0,1,4.45,3.94A7.38,7.38,0,0,1,93.46,32a7.23,7.23,0,0,1-2.4,1.85"
          />
          <path
            className="cls-1"
            d="M204.11,27.3l-12.22-3.87,1.59-5,9.93,3.15.92-2.89-9.93-3.15,2.18-6.88,11.9,3.77.91-2.89L194.32,4.77,187.8,25.31l15.39,4.88Z"
          />
          <path
            className="cls-1"
            d="M222.05,30.31l.32,9.58,3.42,1.81-.41-10.18A6.25,6.25,0,0,0,232,28.13c1.92-3.6.56-7.19-3.36-9.28l-8.8-4.69-10.12,19,2.94,1.57,3.9-7.32Zm-.64-11.93,5.72,3c2.34,1.25,2.88,3.15,1.84,5.08s-2.92,2.58-5.25,1.34L218,24.79Z"
          />
          <path
            className="cls-1"
            d="M127.18,23.57l-15-18-3.09.91-2.88,23.3,3.43-1,.55-4.85,10.51-3.09,3.09,3.77ZM110.5,20.9l1.25-11,7,8.56Z"
          />
          <path
            className="cls-1"
            d="M151.64,9.64l-7.48.7-2.5,3.26,6.81-.64c-.15,2.95-2.26,5.65-6.39,6a7.37,7.37,0,0,1-3.06-.29,7.47,7.47,0,0,1-2.69-1.48,7.58,7.58,0,0,1-1.88-2.44,7.5,7.5,0,0,1-.75-3c-.43-4.58,2.42-8.19,6.83-8.61a7.7,7.7,0,0,1,6.89,2.88h0l2-2.55A10.71,10.71,0,0,0,145.22.7,11.21,11.21,0,0,0,132.68,4a10.89,10.89,0,0,0-2.44,8.16,11,11,0,0,0,7.8,9.62,10.78,10.78,0,0,0,4.34.38c6.57-.61,10-5.68,9.43-11.25C151.79,10.6,151.64,9.64,151.64,9.64Z"
          />
          <path
            className="cls-1"
            d="M170.14,22.54c6.56.76,10.91-3.51,11.55-9.07,0-.28.09-1.25.09-1.25l-7.47-.86L171.2,14l6.79.78c-.76,2.85-3.38,5.06-7.49,4.59a7.64,7.64,0,0,1-2.94-.91,7.76,7.76,0,0,1-2.33-2,7.52,7.52,0,0,1-1.46-5.84c.53-4.57,4.06-7.52,8.46-7A7.75,7.75,0,0,1,175.85,5a7.67,7.67,0,0,1,2.53,2.92h0l2.44-2.1a11.13,11.13,0,0,0-20.19,8.83,10.95,10.95,0,0,0,9.5,7.94"
          />
          <path
            className="cls-1"
            d="M23.43,176.62c-1.06-6.31-6.34-10.17-12.44-9.14s-9.86,6.4-8.79,12.7l1.43,8.55,21.24-3.57ZM6,185l-.88-5.21a7.59,7.59,0,0,1,.05-3,7.48,7.48,0,0,1,6.38-5.78,7.49,7.49,0,0,1,3,0,7.63,7.63,0,0,1,2.79,1.2,7.45,7.45,0,0,1,3.09,5.07l.87,5.21Z"
          />
          <path
            className="cls-1"
            d="M51.66,69.4,49.2,67.63,41.74,78.06,37.48,75l6.07-8.47-2.46-1.77L35,73.24,29.15,69l7.26-10.15L34,57.13,24.75,70,42.27,82.53Z"
          />
          <path
            className="cls-1"
            d="M58.63,41.33c-2.83-2.94-6.67-2.76-9.87.32l-7.18,6.93,15,15.5,2.4-2.32-5.76-6,4.44-4.29,9.2,2.65,2.79-2.68-9.81-2.76a6.35,6.35,0,0,0,.64-3.88,6.27,6.27,0,0,0-1.8-3.5m-2.86,7.82-4.66,4.5-5-5.23,4.67-4.5c1.9-1.84,3.88-1.76,5.4-.18s1.55,3.57-.36,5.41"
          />
          <path
            className="cls-1"
            d="M21.37,158l-4.44-2,.31-11,4.54-1.78.1-3.57L.09,148.37,0,151.59l21.27,9.94Zm-7.18-3.27L4.13,150.1l10.3-4Z"
          />
          <path
            className="cls-1"
            d="M12,133.2a11,11,0,0,0,11.56-4.44,11.15,11.15,0,0,0,1.71-4c1.45-6.44-2.33-11.23-7.8-12.45-.27-.07-1.23-.23-1.23-.23l-1.65,7.33,2.34,3.39,1.5-6.67c2.75,1.06,4.66,3.89,3.76,7.93a7.53,7.53,0,0,1-6.36,6,7.41,7.41,0,0,1-3.07-.2c-4.49-1-7-4.84-6.07-9.16A7.7,7.7,0,0,1,11.5,115h0l-1.82-2.65a11.28,11.28,0,0,0-6.28,12A10.92,10.92,0,0,0,12,133.2Z"
          />
          <path
            className="cls-1"
            d="M19.45,104a10.93,10.93,0,0,0,4.23,1,11,11,0,0,0,10.5-6.58c2.74-6,0-11.46-5.06-13.79L28,84.21l-3.12,6.84,1.58,3.79,2.84-6.22c2.48,1.6,3.77,4.77,2.05,8.54a7.39,7.39,0,0,1-1.77,2.51,7.57,7.57,0,0,1-2.63,1.6,7.48,7.48,0,0,1-3,.42,7.68,7.68,0,0,1-3-.84c-4.19-1.91-5.9-6.18-4.06-10.21a7.65,7.65,0,0,1,5.93-4.54l-1.24-3A10.75,10.75,0,0,0,17,85.32a10.91,10.91,0,0,0-3,4,10.94,10.94,0,0,0-.36,8.5,10.92,10.92,0,0,0,2.3,3.69A10.77,10.77,0,0,0,19.45,104Z"
          />
          <path
            className="cls-1"
            d="M122.84,294.62c-.91,6.11,3,11.35,9.37,12.29l8.57,1.28,3.17-21.3-8.57-1.28c-6.32-.94-11.62,2.89-12.54,9m15.08,10.12-5.23-.77A7.77,7.77,0,0,1,135,288.6l5.23.78Z"
          />
          <path
            className="cls-1"
            d="M42.13,225.62l-2.44,1.8,7.6,10.32-4.21,3.1-6.19-8.39-2.44,1.8,6.19,8.39-5.81,4.29-7.4-10.05L25,238.68l9.38,12.73,17.34-12.79Z"
          />
          <path
            className="cls-1"
            d="M37.11,216.36,29.65,220,27,214.43l5.36-7.92L30.61,203,25,211.51a6.27,6.27,0,0,0-7.38-1.18c-3.67,1.78-4.69,5.49-2.74,9.48l4.37,9,19.36-9.43Zm-16.66,8.11-2.84-5.83c-1.16-2.38-.47-4.23,1.5-5.19s3.88-.37,5,2L27,221.29Z"
          />
          <path
            className="cls-1"
            d="M113.7,285.4l-10.33-3.68-.28-4.86-3.36-1.2,1.57,23.42,3,1.07,16-17.15L117,281.8Zm-9.51,10.37-.64-11,8.11,2.89Z"
          />
          <path
            className="cls-1"
            d="M86.6,267.88c-5.68-3.36-11.39-1.25-14.25,3.56-.14.24-.59,1.11-.59,1.11l6.46,3.83,3.94-1.17-5.88-3.49c1.86-2.29,5.14-3.24,8.71-1.12a7.61,7.61,0,0,1,2.31,2,7.47,7.47,0,0,1,1.3,2.78,7.56,7.56,0,0,1,.09,3.07,7.64,7.64,0,0,1-1.14,2.86c-2.35,4-6.78,5.2-10.58,3a7.68,7.68,0,0,1-2.77-2.69,7.8,7.8,0,0,1-1.12-3.69l-3.09.91A10.84,10.84,0,0,0,75.36,287a11.34,11.34,0,0,0,4.1,1.47,11.19,11.19,0,0,0,4.35-.23,11.07,11.07,0,0,0,3.93-1.88A11.13,11.13,0,0,0,92.07,279a11.1,11.1,0,0,0-5.47-11.13Z"
          />
          <path
            className="cls-1"
            d="M49.7,251.79c-.19.21-.8,1-.8,1l5.53,5.08,4.1-.33-5-4.62c2.29-1.87,5.7-2.11,8.76.69A7.57,7.57,0,0,1,64.09,256a7.62,7.62,0,0,1,.16,6,7.62,7.62,0,0,1-1.71,2.56c-3.11,3.39-7.7,3.7-11,.7a7.68,7.68,0,0,1-2.49-7h0l-3.21.26a10.76,10.76,0,0,0,3.55,9.11,11.1,11.1,0,1,0,14.95-16.4c-4.87-4.46-10.9-3.58-14.68.55"
          />
          <path
            className="cls-1"
            d="M273.63,212.82c-2.85,5.73-.85,12,4.7,14.71s11.73.61,14.58-5.11l3.86-7.75-19.28-9.6Zm19,3.5-2.35,4.73A7.77,7.77,0,0,1,276.07,220a7.73,7.73,0,0,1,.29-5.84l2.35-4.73Z"
          />
          <path
            className="cls-1"
            d="M187.76,283l1,2.88,12.16-4.05,1.65,5-9.89,3.29,1,2.88,9.89-3.3,2.28,6.85-11.84,3.95.95,2.87,15-5-6.8-20.44Z"
          />
          <path
            className="cls-1"
            d="M177.4,284.88l1.15,8.22-6.12.85-5.88-7.55-3.84.54,6.32,8a6.26,6.26,0,0,0-3.4,6.65c.57,4,3.78,6.15,8.18,5.54l9.88-1.38-3-21.33Zm-3.85,19.25c-2.63.37-4.18-.86-4.48-3s.85-3.81,3.47-4.18L179,296l1,7.2Z"
          />
          <path
            className="cls-1"
            d="M264.33,229.14l2.4,4.24-6.68,8.68-4.72-1.24-2.18,2.83,22.77,5.74,1.95-2.55-11.36-20.53Zm9.33,16.49L263,242.83l5.25-6.82Z"
          />
          <path
            className="cls-1"
            d="M241.69,253.73c-5,4.37-4.71,10.45-1,14.65.18.22.86.91.86.91l5.65-5,.11-4.11-5.13,4.52c-1.61-2.48-1.49-5.9,1.62-8.64a7.61,7.61,0,0,1,5.69-2,7.57,7.57,0,0,1,5.31,2.84c3,3.45,2.86,8.05-.47,11a7.69,7.69,0,0,1-7.26,1.72l-.09,3.22a10.76,10.76,0,0,0,9.44-2.57,11.1,11.1,0,0,0-14.72-16.61Z"
          />
          <path
            className="cls-1"
            d="M219,269.73c-5.75,3.24-6.77,9.25-4,14.13.14.24.66,1.06.66,1.06l6.54-3.69.95-4-5.95,3.36c-1.07-2.76-.24-6.08,3.36-8.12a7.74,7.74,0,0,1,2.91-1,7.59,7.59,0,0,1,3.07.26,7.49,7.49,0,0,1,2.71,1.45,7.59,7.59,0,0,1,1.9,2.42c2.27,4,1.14,8.46-2.72,10.64a7.67,7.67,0,0,1-3.71,1.06,7.78,7.78,0,0,1-3.75-.87l-.75,3.13a10.78,10.78,0,0,0,4.95.88A10.62,10.62,0,0,0,230,289a11,11,0,0,0,3.34-2.81,11.13,11.13,0,0,0,2.33-8.24,11.09,11.09,0,0,0-4.24-7.45,11.14,11.14,0,0,0-3.92-1.93,11,11,0,0,0-4.35-.27A10.88,10.88,0,0,0,219,269.73Z"
          />
          <path
            className="cls-1"
            d="M262.57,58.93c4.32-4.4,4.2-11-.36-15.45L256,37.41,240.94,52.78l6.18,6.07c4.57,4.48,11.1,4.5,15.45.08m-6.25-17,3.77,3.7A7.52,7.52,0,0,1,262.45,51a7.6,7.6,0,0,1-.62,3,7.49,7.49,0,0,1-1.76,2.48,7.44,7.44,0,0,1-5.39,2.46,7.42,7.42,0,0,1-3-.57,7.53,7.53,0,0,1-2.5-1.71L245.43,53Z"
          />
          <path
            className="cls-1"
            d="M287.31,162.18l3,0-.09-12.81,5.23,0,.08,10.43,3,0-.07-10.42,7.22,0,.08,12.48,3,0-.11-15.81L287.2,146Z"
          />
          <path
            className="cls-1"
            d="M285.92,172.63l8.17,1.45L293,180.16l-9,3.26-.68,3.81,9.56-3.53a6.27,6.27,0,0,0,1.76,3.52,6.34,6.34,0,0,0,3.52,1.77c4,.71,7-1.69,7.79-6.06l1.74-9.82-21.2-3.76Zm10,8.35L297,174.6l7.15,1.27L303,182.25c-.46,2.61-2.11,3.7-4.26,3.32s-3.35-2-2.9-4.59"
          />
          <path
            className="cls-1"
            d="M259.77,72.73l4.78-1,6.18,9-2.63,4.1,2,2.95L282.61,68l-1.82-2.66-23,4.47ZM278.33,69l-6,9.3-4.87-7.1Z"
          />
          <path
            className="cls-1"
            d="M276.17,101.86c2.61,6.06,8.48,7.71,13.62,5.49.26-.11,1.13-.54,1.13-.54l-3-6.9-3.87-1.37,2.71,6.28c-2.85.76-6.07-.41-7.71-4.21a7.51,7.51,0,0,1-.1-6,7.75,7.75,0,0,1,1.73-2.54,7.56,7.56,0,0,1,2.61-1.63c4.22-1.83,8.54-.23,10.29,3.83a7.65,7.65,0,0,1,.66,3.8,7.55,7.55,0,0,1-1.27,3.64h0l3,1.07a10.91,10.91,0,0,0,1.41-4.83A10.76,10.76,0,0,0,296.5,93a11.26,11.26,0,0,0-2.43-3.66,11.1,11.1,0,0,0-18.21,3.91,11.29,11.29,0,0,0-.72,4.34A11.11,11.11,0,0,0,276.17,101.86Z"
          />
          <path
            className="cls-1"
            d="M293,115.25a11,11,0,0,0-8.73,8.78,10.8,10.8,0,0,0,.08,4.35c1.31,6.47,6.71,9.3,12.2,8.18.27-.05,1.21-.3,1.21-.3l-1.49-7.36-3.51-2.14,1.36,6.7c-2.95.16-5.86-1.64-6.68-5.7a7.55,7.55,0,0,1,6.25-9.1c4.51-.91,8.4,1.54,9.29,5.87a7.77,7.77,0,0,1-.14,3.86,7.68,7.68,0,0,1-2,3.3h0l2.74,1.68a11.07,11.07,0,0,0-6.25-18.2A10.76,10.76,0,0,0,293,115.25Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const daggerBadgeLoading = (
  <svg viewBox="0 0 74.26 74.27">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M36.73,53.79h5.4l14.2-15.36h-6ZM55.19,38.93,41.91,53.29H37.84L50.51,38.93Z" />
        <path d="M61.74,37.13,43.48,17.39H22.29V56.88H43.48ZM22.79,56.38V17.89H43.26l17.8,19.24L43.26,56.38Z" />
        <path d="M50.28,35.84h6L42.13,20.48h-5.4L50.21,35.75ZM41.91,21,55.19,35.34H50.51L37.84,21Z" />
        <path d="M40.81,35.84h6L33.29,20.56l-.07-.08H27.36L40.73,35.75ZM33,21,45.67,35.34H41L28.46,21Z" />
        <path d="M40.81,38.43,27.36,53.79h5.86L46.77,38.43ZM33,53.29H28.46L41,38.93h4.64Z" />
        <path d="M38.49,37.13l-5.61-6.42V43.56ZM33.38,32l4.45,5.09-4.45,5.09Z" />
        <path d="M30.29,27.75l-4.91-5.62v30l4.91-5.61Zm-.5,18.58L25.88,50.8V23.47l3.91,4.46Z" />
        <g className="dagger-badge-circle-text">
          <path d="M19.64,4.38l.58-.29L21.8,7.21l1.29-.64.25.51L21.48,8Z" />
          <path d="M22.46,5.22A2.13,2.13,0,1,1,25.2,6.4a2.08,2.08,0,0,1-2.73-1.17Zm.6-.25A1.49,1.49,0,1,0,25.83,3.9,1.49,1.49,0,1,0,23.05,5Z" />
          <path d="M27.74,5.43l-.68.17.61-4.35.57-.15,2.6,3.54-.69.17L29.54,4l-1.68.43Zm.38-3.52L27.9,3.85l1.35-.34Z" />
          <path d="M30.63.57,31.87.4a2,2,0,0,1,1.75.44,2.08,2.08,0,0,1,.62,1.3,1.88,1.88,0,0,1-1.79,2.29l-1.29.17ZM31.73,4l.68-.09A1.31,1.31,0,0,0,33.6,2.23a1.59,1.59,0,0,0-.46-1A1.31,1.31,0,0,0,32,1l-.69.09Z" />
          <path d="M34.84.1l.65,0,.21,4.07-.65,0Z" />
          <path d="M39.14,3.17,39.22.08h.62l-.11,4.07-.59,0L37.1,1,37,4.08l-.62,0L36.51,0l.61,0Z" />
          <path d="M44,1.81a1.69,1.69,0,0,0-.31-.43A1.43,1.43,0,0,0,42.87,1a1.48,1.48,0,0,0-1.71,1.28A1.48,1.48,0,0,0,42.39,4a1.51,1.51,0,0,0,1-.15A1.35,1.35,0,0,0,44,3.22l-1.74-.28.09-.52,2.47.39a2.36,2.36,0,0,1-.32.88,2.18,2.18,0,0,1-2.15.89,2.1,2.1,0,0,1-1.78-2.43A2.08,2.08,0,0,1,43,.41a2.12,2.12,0,0,1,1.74,1.52Z" />
          <path d="M45.5,5.16,44.91,5l.18-.7.58.15Z" />
          <path d="M47,5.61l-.58-.18.21-.68.58.17Z" />
          <path d="M48.53,6.11,48,5.91l.24-.67.57.2Z" />
          <path d="M51.87,3.06l.59.27L51,6.51l1.3.6-.24.52-1.89-.87Z" />
          <path d="M53,5.79a2.13,2.13,0,1,1,.78,2.88A2.1,2.1,0,0,1,53,5.79Zm.57.31a1.48,1.48,0,1,0,2.56,1.5,1.5,1.5,0,0,0-.5-2.08,1.48,1.48,0,0,0-2.06.57Z" />
          <path d="M56.1,10.05l-.56-.43,3.79-2.23.47.36L58.65,12l-.57-.42.28-1L57,9.51Zm3-1.9-1.66,1,1.11.83Z" />
          <path d="M61.7,9.27l.91.87a2,2,0,0,1,.75,1.64,2.08,2.08,0,0,1-.63,1.29,1.87,1.87,0,0,1-2.91,0l-.94-.91Zm-2,3,.5.48a1.3,1.3,0,0,0,2-.09,1.63,1.63,0,0,0,.48-1,1.3,1.3,0,0,0-.47-1.05l-.5-.48Z" />
          <path d="M64.67,12.26l.43.49-3,2.69L61.63,15Z" />
          <path d="M65,17.55l2.46-1.87.38.5-3.25,2.45-.36-.48,1.2-3.57-2.47,1.88-.38-.5,3.25-2.45.37.49Z" />
          <path d="M69.06,20.51a1.63,1.63,0,0,0,.15-.52,1.42,1.42,0,0,0-.19-.88,1.54,1.54,0,0,0-2.68,1.52,1.56,1.56,0,0,0,.73.67,1.31,1.31,0,0,0,.87.06l-.87-1.53.46-.26,1.24,2.18a2.28,2.28,0,0,1-.89.29,2.15,2.15,0,0,1-2-1.13,2.11,2.11,0,1,1,3.68-2.08,2.13,2.13,0,0,1-.11,2.31Z" />
          <path d="M67.38,23.78l-.25-.54.65-.3.25.55Z" />
          <path d="M68,25.22l-.22-.55.67-.27.22.56Z" />
          <path d="M68.52,26.74l-.19-.56L69,26l.2.56Z" />
          <path d="M73,27.44l.15.63-3.39.84.34,1.39-.56.14-.5-2Z" />
          <path d="M71.54,30a2.13,2.13,0,0,1,.63,4.22A2.13,2.13,0,0,1,71.54,30Zm.11.63a1.49,1.49,0,1,0,1.74,1.26,1.5,1.5,0,0,0-1.74-1.26Z" />
          <path d="M70.15,35.1v-.7L74.24,36l0,.59-4,1.74v-.7l1-.41,0-1.74Zm3.35,1.16-1.85-.64,0,1.38Z" />
          <path d="M74.25,39l-.12,1.25a2,2,0,0,1-.81,1.61,2.07,2.07,0,0,1-1.4.32,1.87,1.87,0,0,1-1.84-2.25l.12-1.3Zm-3.54.33-.07.68A1.31,1.31,0,0,0,72,41.54,1.64,1.64,0,0,0,73,41.3a1.32,1.32,0,0,0,.53-1l.06-.69Z" />
          <path d="M73.77,43.17l-.11.64-4-.69.11-.64Z" />
          <path d="M69.82,46.72l3,.77-.16.6-3.94-1,.15-.58,3.54-1.28-3-.77.16-.6,3.94,1-.15.59Z" />
          <path d="M70.05,51.73a1.49,1.49,0,0,0,.49-.2,1.42,1.42,0,0,0,.58-.69,1.54,1.54,0,0,0-2.86-1.15,1.53,1.53,0,0,0-.07,1,1.25,1.25,0,0,0,.5.72l.65-1.63.49.2-.94,2.32a2.23,2.23,0,0,1-.78-.51,2.19,2.19,0,0,1-.38-2.29,2.1,2.1,0,0,1,2.77-1.19,2.15,2.15,0,0,1-.73,4.12Z" />
          <path d="M66.44,52.47l.27-.53.64.33-.28.53Z" />
          <path d="M65.67,53.85l.3-.52.62.36-.3.52Z" />
          <path d="M64.83,55.21l.33-.51.6.39-.32.5Z" />
          <path d="M67.06,59.14l-.39.51L63.9,57.51,63,58.65l-.45-.35,1.27-1.65Z" />
          <path d="M64.16,59.61a2.13,2.13,0,0,1-2.9,3.12,2.13,2.13,0,0,1,2.9-3.13Zm-.42.48a1.49,1.49,0,1,0,.1,2.14,1.49,1.49,0,0,0-.1-2.14Z" />
          <path d="M59.31,61.7l.54-.45,1.33,4.19-.45.37-3.87-2.06.54-.45.91.49,1.33-1.11ZM60.49,65l-.65-1.84-1.06.89Z" />
          <path d="M58.82,67.33l-1,.7a2,2,0,0,1-1.76.36,2.09,2.09,0,0,1-1.13-.9,1.87,1.87,0,0,1,.61-2.84l1.09-.72Zm-2.47-2.56-.57.38a1.3,1.3,0,0,0-.37,2,1.67,1.67,0,0,0,.85.69,1.29,1.29,0,0,0,1.12-.23l.58-.38Z" />
          <path d="M55.24,69.57l-.57.31-2-3.57.57-.31Z" />
          <path d="M50,68.68l1.27,2.82-.57.26L49.06,68l.54-.24,3.21,2-1.28-2.82.58-.26,1.66,3.72-.56.24Z" />
          <path d="M46.24,72a1.47,1.47,0,0,0,.47.27,1.45,1.45,0,0,0,.9,0,1.54,1.54,0,0,0-.88-3,1.61,1.61,0,0,0-.82.56,1.3,1.3,0,0,0-.25.84l1.68-.51.15.51-2.4.72A2.5,2.5,0,0,1,45,70.5a2.19,2.19,0,0,1,1.56-1.73,2.1,2.1,0,0,1,2.65,1.42,2.08,2.08,0,0,1-1.45,2.62,2.12,2.12,0,0,1-2.22-.62Z" />
          <path d="M43.42,69.61l.59-.12.14.7-.59.12Z" />
          <path d="M41.87,69.87l.59-.09.11.71-.59.09Z" />
          <path d="M40.26,70.06l.6-.06.07.71-.59.07Z" />
          <path d="M38.59,74.26h-.65l-.06-3.5-1.43,0v-.57l2.08,0Z" />
          <path d="M36.42,72.28A2.13,2.13,0,1,1,34.48,70a2.08,2.08,0,0,1,1.94,2.26Zm-.64,0a1.49,1.49,0,1,0-1.61,1.41,1.49,1.49,0,0,0,1.61-1.41Z" />
          <path d="M31.75,69.8l.69.14L30,73.59l-.58-.12-.8-4.31.69.14.19,1,1.7.34Zm-1.88,3,1-1.66-1.35-.28Z" />
          <path d="M27,72.92l-1.19-.39a2,2,0,0,1-1.38-1.15,2.1,2.1,0,0,1,0-1.44,1.87,1.87,0,0,1,2.61-1.29l1.24.41Zm.48-3.52-.65-.22a1.3,1.3,0,0,0-1.78.95,1.7,1.7,0,0,0,0,1.09A1.35,1.35,0,0,0,26,72l.65.22Z" />
          <path d="M23.06,71.52l-.6-.26L24,67.51l.6.26Z" />
          <path d="M20.5,66.86l-1.42,2.76-.56-.29,1.88-3.62.53.28.45,3.74L22.81,67l.56.29-1.89,3.61-.54-.28Z" />
          <path d="M15.57,66a1.89,1.89,0,0,0,.08.53,1.5,1.5,0,0,0,.55.71A1.54,1.54,0,0,0,18,64.69,1.57,1.57,0,0,0,17,64.4a1.29,1.29,0,0,0-.81.33l1.44,1-.3.43-2.06-1.43a2.32,2.32,0,0,1,.67-.65,2.17,2.17,0,0,1,2.32.14A2.11,2.11,0,1,1,15,65.56Z" />
          <path d="M15.67,62.29l.45.38-.46.55-.45-.38Z" />
          <path d="M14.48,61.23l.44.41-.48.52-.44-.4Z" />
          <path d="M13.35,60.12l.42.42-.5.51-.42-.43Z" />
          <path d="M9,61.42l-.41-.5,2.7-2.22-.91-1.11.44-.36,1.32,1.61Z" />
          <path d="M9.22,58.48a2.13,2.13,0,1,1,.55-2.92,2.08,2.08,0,0,1-.55,2.92ZM8.84,58a1.48,1.48,0,1,0-2.11-.38A1.49,1.49,0,0,0,8.84,58Z" />
          <path d="M8.25,53.29l.32.63-4.38.36-.27-.52L6.8,50.44l.32.63-.69.78.8,1.55Zm-3.51.41,1.94-.22-.63-1.24Z" />
          <path d="M2.87,51.55l-.44-1.18a2,2,0,0,1,0-1.8,2.13,2.13,0,0,1,1.13-.89,1.87,1.87,0,0,1,2.63,1.24l.45,1.22Zm3.05-1.82-.24-.65a1.3,1.3,0,0,0-1.85-.8A1.66,1.66,0,0,0,3,49a1.3,1.3,0,0,0,0,1.14l.24.65Z" />
          <path d="M1.48,47.55l-.17-.62,3.92-1.11.17.62Z" />
          <path d="M3.53,42.67l-3,.6-.12-.62,4-.78.11.59L1.85,45.14l3-.6.12.61-4,.78-.11-.6Z" />
          <path d="M1.16,38.24a1.48,1.48,0,0,0-.36.39,1.43,1.43,0,0,0-.22.88A1.47,1.47,0,0,0,2.2,40.9a1.47,1.47,0,0,0,1.45-1.59,1.53,1.53,0,0,0-.36-.92A1.25,1.25,0,0,0,2.53,38l.12,1.75-.53,0L2,37.24a2.56,2.56,0,0,1,.92.12,2.2,2.2,0,0,1,1.34,1.91,2.1,2.1,0,0,1-2,2.27,2.09,2.09,0,0,1-2.24-2,2.13,2.13,0,0,1,1.11-2Z" />
          <path d="M4.1,36l0,.6-.71,0V36Z" />
          <path d="M4.19,34.44l0,.59L3.43,35l0-.6Z" />
          <path d="M4.35,32.85l-.07.59-.71-.08.07-.6Z" />
          <path d="M.63,30.29l.13-.64,3.43.72L4.48,29l.57.12-.43,2Z" />
          <path d="M3.05,28.6a2.09,2.09,0,0,1-1.4-2.67,2.11,2.11,0,1,1,1.4,2.67ZM3.22,28a1.49,1.49,0,1,0,.9-2.83A1.49,1.49,0,1,0,3.22,28Z" />
          <path d="M6.51,24.62l-.29.64-3-3.21.24-.53,4.39.18-.29.65-1-.05-.72,1.58ZM4,22.12,5.39,23.5,6,22.24Z" />
          <path d="M4.53,19.31l.66-1.07a2,2,0,0,1,1.43-1.09,2.07,2.07,0,0,1,1.4.33,1.87,1.87,0,0,1,.67,2.83L8,21.42Zm3.33,1.26L8.22,20A1.31,1.31,0,0,0,7.69,18a1.56,1.56,0,0,0-1.05-.26,1.3,1.3,0,0,0-.92.68l-.36.6Z" />
          <path d="M6.81,15.74l.38-.53,3.29,2.39-.38.52Z" />
          <path d="M11.89,14.31l-2.37-2,.41-.48L13,14.47l-.38.46L8.9,14.54l2.37,2-.41.48-3.1-2.64.4-.47Z" />
          <path d="M13.88,9.69a2,2,0,0,0-.54,0,1.53,1.53,0,1,0,1.25,2.66,1.49,1.49,0,0,0,.49-.86,1.21,1.21,0,0,0-.13-.86l-1.3,1.18-.35-.39L15.16,9.7a2.33,2.33,0,0,1,.47.79A2.19,2.19,0,0,1,15,12.73a2.1,2.1,0,0,1-3-.14,2.08,2.08,0,0,1,.18-3,2.11,2.11,0,0,1,2.27-.4Z" />
          <path d="M17.44,10.6,17,11l-.43-.57L17,10Z" />
          <path d="M18.74,9.68l-.49.34-.41-.59.49-.34Z" />
          <path d="M20.06,8.84l-.51.32-.37-.61.51-.32Z" />
        </g>
      </g>
    </g>
  </svg>
)

export const linkedInIcon = (
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none">
    <path
      d="M24.682 0.506095H1.97C1.45571 0.498342 0.959369 0.695034 0.589996 1.05297C0.220623 1.41091 0.00842129 1.90082 0 2.4151V25.2491C0.00815775 25.7635 0.220277 26.2535 0.589708 26.6115C0.95914 26.9695 1.45563 27.1661 1.97 27.1581H24.682C25.1964 27.1661 25.6929 26.9695 26.0623 26.6115C26.4317 26.2535 26.6438 25.7635 26.652 25.2491V2.4151C26.6438 1.90073 26.4317 1.41066 26.0623 1.05267C25.6929 0.69468 25.1964 0.498076 24.682 0.506095ZM8.1 22.8061H4.111V10.8061H8.1V22.8061ZM6.106 9.1731H6.08C5.79442 9.19086 5.50825 9.14949 5.2394 9.05156C4.97055 8.95364 4.72481 8.80127 4.51756 8.604C4.3103 8.40673 4.146 8.1688 4.03494 7.90511C3.92387 7.64142 3.86843 7.35764 3.87209 7.07153C3.87575 6.78543 3.93842 6.50316 4.05619 6.24239C4.17396 5.98162 4.34429 5.74797 4.55652 5.55606C4.76875 5.36416 5.01831 5.21812 5.28957 5.1271C5.56084 5.03608 5.84797 5.00204 6.133 5.0271C6.41966 5.0054 6.7077 5.04335 6.97895 5.13857C7.2502 5.23379 7.49877 5.38421 7.70896 5.58032C7.91916 5.77644 8.08642 6.01399 8.20019 6.278C8.31396 6.54201 8.37176 6.82673 8.36996 7.11421C8.36816 7.40168 8.30678 7.68565 8.18971 7.94821C8.07264 8.21077 7.90242 8.44621 7.68978 8.63968C7.47714 8.83314 7.2267 8.98043 6.95428 9.07224C6.68186 9.16405 6.39236 9.19839 6.106 9.1731ZM22.48 22.8061H18.493V16.3921C18.493 14.7791 17.916 13.6791 16.473 13.6791C16.0232 13.6819 15.5852 13.8235 15.219 14.0847C14.8528 14.3458 14.5762 14.7137 14.427 15.1381C14.3249 15.4519 14.2805 15.7815 14.296 16.1111V22.8111H10.307C10.307 22.8111 10.359 11.9381 10.307 10.8111H14.295V12.5061C14.6562 11.8794 15.1821 11.3635 15.8156 11.0144C16.4492 10.6654 17.1663 10.4965 17.889 10.5261C20.513 10.5261 22.48 12.2411 22.48 15.9261V22.8061Z"
      fill="white"
    />
  </svg>
)

export const expandIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M20 0.500244C9.23045 0.500244 0.5 9.23069 0.5 20.0002C0.5 30.7698 9.23045 39.5002 20 39.5002C30.7696 39.5002 39.5 30.7698 39.5 20.0002C39.5 9.23069 30.7696 0.500244 20 0.500244Z"
      stroke="black"
    />
    <path d="M20 9.98209L20 30.0171" stroke="black" />
    <path d="M30.0172 20L9.98218 20" stroke="black" />
  </svg>
)

export const nextIcon = (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
    <path
      d="M23 45.5C35.4264 45.5 45.5 35.4264 45.5 23C45.5 10.5736 35.4264 0.5 23 0.5C10.5736 0.5 0.5 10.5736 0.5 23C0.5 35.4264 10.5736 45.5 23 45.5Z"
      stroke="white"
    />
    <g>
      <path
        d="M19.646 11.759L30.04 22.999L19.646 34.239"
        stroke="white"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
)

export const prevIcon = (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
    <g>
      <path
        d="M23 0.5C10.5736 0.5 0.5 10.5736 0.5 23C0.5 35.4264 10.5736 45.5 23 45.5C35.4264 45.5 45.5 35.4264 45.5 23C45.5 10.5736 35.4264 0.5 23 0.5Z"
        stroke="white"
      />
      <g>
        <path
          d="M26.354 34.241L15.96 23.001L26.354 11.761"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
    </g>
  </svg>
)

export const arrowDownIcon = (
  <svg width="24" height="12" viewBox="0 0 24 12" fill="none">
    <g>
      <path
        d="M23.6582 0.838989L12.4182 11.233L1.1782 0.838989"
        stroke="black"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
)

export const playIcon = (
  <svg width="162" height="162" viewBox="0 0 162 162" fill="none">
    <path
      d="M81 162C125.735 162 162 125.735 162 81C162 36.2649 125.735 0 81 0C36.2649 0 0 36.2649 0 81C0 125.735 36.2649 162 81 162Z"
      fill="white"
    />
    <path d="M114 80.953L64 110.907V51L114 80.953Z" fill="white" />
  </svg>
)
