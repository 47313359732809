import { Link } from "react-router-dom"

const SiteMenuComponent = (props) => {
  const { items } = props

  const handleShowLoader = (dest) => {
    if (dest === window.location.pathname) return
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
  }

  return (
    <ul>
      {items?.PageItem?.map((p) => {
        let dest
        let props
        let clickHandler = () => handleShowLoader(dest)

        if (p.PageLink?.data) {
          const pageSlug = p.PageLink.data.attributes.Slug
          dest = pageSlug === "home" ? "/" : `/${pageSlug}`
        } else if (p.CaseStudyPageLink?.data) {
          dest = `/study/${p.CaseStudyPageLink.data.attributes.Slug}`
        } else if (p.ExternalLink) {
          dest = p.ExternalLink
          props = { target: "_blank", rel: "noopener noreferrer" }
          clickHandler = null
        } else {
          dest = "/"
        }

        return (
          <li key={p.id}>
            <Link to={dest} {...props} onClick={clickHandler}>
              {p.PageName}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default SiteMenuComponent
