import "./people-list.component.css"
import { Remarkable } from "remarkable"

const PeopleListComponent = (props) => {
  const { id, Heading, People } = props
  const md = new Remarkable()

  return (
    <div className={`dagger-people-list people-list-${id}`}>
      <h2>{Heading}</h2>
      {People?.length > 0 && (
        <ul>
          {People.map((p) => {
            const { Media, Name, Title, Bio } = p

            return (
              <li key={p.id}>
                <div className="people-image-container">
                  <img
                    src={`${Media.data.attributes.url}`}
                    alt={Media.data.attributes.alternateText}
                  />
                </div>
                <div className="people-info-container">
                  <h4>
                    {Name}, {Title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: md.render(Bio),
                    }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default PeopleListComponent
