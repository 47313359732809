import "./ig-feed.component.css"
import { useEffect, useRef, useState } from "react"
import ModalComponent from "../modal/modal.component"
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm"
import "@glidejs/glide/dist/css/glide.core.css"
import { nextIcon, prevIcon } from "../../icons"

const IgFeedComponent = (props) => {
  const { CtaButtonLabel, Heading } = props
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const glideRef = useRef()

  useEffect(() => {
    const doFetch = async () => {
      try {
        const res = await fetch(
          "https://feeds.behold.so/cHqElfY4S8G5kzIqOACp"
        ).then((r) => r.json())
        setItems(res)
      } catch (e) {
        // todo : handle error
        console.error(e)
      }
    }

    doFetch()
  }, [])

  const getItemContent = () => {
    const i = selectedItem
    const isVideo = i.mediaType === "VIDEO"
    return (
      <div className="dagger-ig-selection">
        <div className="dagger-ig-selection-image">
          {!isVideo && <img src={i.mediaUrl} alt={i.prunedCaption} />}
          {isVideo && <video src={i.mediaUrl} autoPlay controls playsInline />}
        </div>
        <div className="dagger-ig-selection-meta">
          <p>{i.caption}</p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (items?.length < 1 || glideRef.current) return

    glideRef.current = new Glide(".glide-ig-slider", {
      startAt: 0,
      type: "carousel",
      perView: 4,
      focusAt: 0,
      gap: 40,
      peek: {
        before: 0,
        after: 150,
      },
      breakpoints: {
        800: {
          perView: 2,
          peek: 0,
        },
        1000: {
          perView: 3,
          peek: 0,
        },
        1200: {
          perView: 3,
          peek: 0,
        },
        1400: {
          perView: 3,
          peek: {
            before: 0,
            after: 150,
          },
        },
      },
    }).mount({ Controls, Breakpoints })
  }, [items])

  const handleItemClick = (e) => {
    if (e.target?.parentElement) {
      const itemId = e.target.parentElement.getAttribute("data-item-id")
      const item = items.find((i) => i.id === itemId)
      if (item) {
        setSelectedItem(item)
      }
    }
  }

  return (
    <div className="dagger-ig">
      <h2>{Heading}</h2>
      <div className="glide glide-ig-slider">
        <div className="dagger-ig-items glide__track" data-glide-el="track">
          <div className="glide__slides" onClick={handleItemClick}>
            {items?.map((i) => {
              const isVideo = i.mediaType === "VIDEO"
              const mediaUrl = isVideo ? i.thumbnailUrl : i.mediaUrl
              return (
                <div
                  key={i.id}
                  data-item-id={i.id}
                  className="dagger-ig-item glide__slide"
                >
                  {<img src={mediaUrl} alt={i.prunedCaption} />}
                </div>
              )
            })}
          </div>
          <div data-glide-el="controls" className="glide-controls">
            <button data-glide-dir="<">{prevIcon}</button>
            <button data-glide-dir=">">{nextIcon}</button>
          </div>
        </div>
      </div>
      <a
        className="dagger-outline-btn"
        target="_blank"
        href="https://www.instagram.com/heyitsdagger/"
        rel="noreferrer"
      >
        {CtaButtonLabel}
      </a>
      {selectedItem && (
        <ModalComponent
          inverted
          content={getItemContent()}
          onClose={() => setSelectedItem(false)}
        />
      )}
    </div>
  )
}

export default IgFeedComponent
