import "./phone-holder.component.css"
import frame from "./media/2-frame.png"
import border from "./media/3-border.png"
import icons from "./media/4-icons.png"
import tabs from "./media/5-tabs.png"

const PhoneHolderComponent = (props) => {
  const { content, avatar, handle, shadowColor } = props

  const getStyles = () => {
    if (shadowColor) {
      return { filter: `drop-shadow(0 5px 12px ${shadowColor})` }
    }

    return null
  }

  return (
    <div className="dagger-phone-holder-container">
      <div className="dagger-phone-holder">
        <img
          className="dagger-phone-frame"
          src={frame}
          alt="phone"
          style={getStyles()}
        />
        <img className="dagger-phone-border" src={border} alt="border" />
        <div className="dagger-phone-content">
          <div className="dagger-phone-header">
            <div className="dagger-phone-header-status">
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              <img src={icons} alt="icons" />
            </div>
            <div className="dagger-phone-header-meta">
              {avatar.data?.attributes && (
                <img
                  src={avatar.data.attributes.url}
                  alt={avatar.data.attributes.name}
                />
              )}
              {handle && <h6>{handle}</h6>}
              <span>...</span>
            </div>
          </div>
          {content}
          <div className="dagger-phone-footer">
            <img src={tabs} alt="tab bar" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhoneHolderComponent
