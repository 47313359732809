import "./text-scroll.component.css"
import { useLayoutEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

const TextScrollComponent = (props) => {
  const {
    id,
    Heading,
    Copy,
    ButtonLabel,
    PageLink,
    DisableScrollAnimation,
    Theme,
  } = props
  const elRef = useRef()
  const navigate = useNavigate()

  const handleInvertBackground = (invert) => {
    if (!elRef.current) {
      return
    }

    if (invert) {
      elRef.current.classList.add("inverted")
    } else {
      elRef.current.classList.remove("inverted")
    }
  }

  const theTheme = Theme ? `theme-${Theme.toLowerCase().replace("/", "-")}` : ""

  // Invert colors on scroll
  useLayoutEffect(() => {
    if (DisableScrollAnimation) return

    window.ScrollTrigger.create({
      trigger: `.text-scroll-${id}`,
      start: "-160% top",
      onEnter: () => handleInvertBackground(true),
      onLeave: () => handleInvertBackground(false),
      onLeaveBack: () => handleInvertBackground(false),
      onEnterBack: () => handleInvertBackground(true),
    })
  }, [id, DisableScrollAnimation])

  const handlePageClick = () => {
    document.dispatchEvent(new CustomEvent("EVENTS.START_LOADING"))
    navigate(`/${PageLink.data.attributes.Slug}`)
  }

  return (
    <div
      className={`dagger-text-scroll text-scroll-${id} ${theTheme}`}
      ref={elRef}
    >
      <h2>{Heading}</h2>
      <div className="dagger-text-scroll-copy">
        <p>{Copy}</p>
        {ButtonLabel && PageLink?.data && (
          <button className="dagger-outline-btn" onClick={handlePageClick}>
            {ButtonLabel}
          </button>
        )}
      </div>
    </div>
  )
}

export default TextScrollComponent
