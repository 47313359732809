import "./press-slider.component.css"
import { useEffect, useRef } from "react"
import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm"
import "@glidejs/glide/dist/css/glide.core.css"
import { nextIcon, prevIcon } from "../../icons"

const PressSliderComponent = (props) => {
  const { Heading, Items } = props
  const glideRef = useRef()

  const handleNav = (href) => {
    if (href && href.indexOf("https://") > -1) {
      window.open(href, "_blank")
    }
  }

  const createPressItem = (item) => {
    const { Heading, Subheading, Media } = item

    return (
      <div
        key={item.id}
        data-item-id={item.id}
        className="dagger-press-item glide__slide"
      >
        <div className="dagger-press-item-image" data-item-id={item.id}>
          <img
            src={`${Media.data.attributes.url}`}
            alt={Media.data.attributes.altText}
          />
        </div>
        <h6>{Heading}</h6>
        {Subheading && <h5>{Subheading}</h5>}
      </div>
    )
  }

  useEffect(() => {
    if (Items?.length < 1 || glideRef.current) return

    glideRef.current = new Glide(".glide-press-slider", {
      type: "carousel",
      startAt: 0,
      perView: 2,
      focusAt: 0,
      peek: {
        before: 0,
        after: 0,
      },
      breakpoints: {
        800: {
          perView: 1,
          peek: 0,
        },
      },
    }).mount({ Controls, Breakpoints })
  }, [Items])

  const handleItemClick = (e) => {
    if (e.target?.parentElement) {
      const itemId = e.target.parentElement.getAttribute("data-item-id")
      const item = Items.find((i) => i.id.toString() === itemId.toString())
      if (item) {
        handleNav(item.Link)
      }
    }
  }

  return (
    <div className="dagger-press-slider">
      <h6>{Heading}</h6>
      <div className="glide glide-press-slider">
        <div className="dagger-press-items glide__track" data-glide-el="track">
          <div className="glide__slides" onClick={handleItemClick}>
            {Items?.map((i, ind) => createPressItem(i, ind))}
          </div>
          <div data-glide-el="controls" className="glide-controls">
            <button data-glide-dir="<">{prevIcon}</button>
            <button data-glide-dir=">">{nextIcon}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PressSliderComponent
