import { useRef, useState } from "react"
import { playIcon } from "../../icons"
import "./case-study-hero.component.css"
import { Remarkable } from "remarkable"
import VideoModalComponent from "../video-modal/video-modal.component"

const CaseStudyHeroComponent = (props) => {
  const { Heading, Copy, Media, id } = props
  const [showModal, setShowModal] = useState()
  const videoElRef = useRef()
  const isImage = Media?.data.attributes.mime.indexOf("image") > -1
  const md = new Remarkable()

  const handleShowVideo = (c) => {
    setShowModal({
      id: id,
      src: Media.data.attributes.url,
      UseLandscapeAspectRatio: true,
    })

    videoElRef.current.pause()
  }

  const handleCloseVideo = () => {
    setShowModal(false)
    videoElRef.current.play()
  }

  return (
    <div className="dagger-case-study-hero">
      {showModal && (
        <VideoModalComponent {...showModal} onClose={handleCloseVideo} />
      )}
      <div className="dagger-169">
        {isImage && <img src={`${Media?.data.attributes.url}`} alt={Heading} />}
        {!isImage && (
          <>
            <div className="video-play-icon">{playIcon}</div>
            <video
              ref={videoElRef}
              src={`${Media?.data.attributes.url}`}
              autoPlay
              muted
              loop
              playsInline
              onClick={handleShowVideo}
            />
          </>
        )}
      </div>
      {Heading && <h2>{Heading}</h2>}
      {Copy && (
        <div
          className="dagger-case-study-hero-copy"
          dangerouslySetInnerHTML={{ __html: md.render(Copy) }}
        />
      )}
    </div>
  )
}

export default CaseStudyHeroComponent
