import { useState } from "react"
import { playIcon } from "../../icons"
import "./case-study-media-container.component.css"
import { Remarkable } from "remarkable"
import VideoModalComponent from "../video-modal/video-modal.component"
import PhoneHolderComponent from "../phone-holder/phone-holder.component"

const CaseStudyMediaContainerComponent = (props) => {
  const {
    CaseStudyMedia,
    AddColoredBorder,
    ColoredBorderWidth,
    HexColor,
    UseLandscapeAspectRatio,
    AddExtraPadding,
    BackgroundColor,
    TextColor,
  } = props

  const [showModal, setShowModal] = useState(false)
  const md = new Remarkable()
  const hasMedia = CaseStudyMedia?.filter((m) => m.Media?.data).length > 0

  const getStyles = () => {
    let border = "none"
    let padding = 0
    let background = "transparent"
    let width = "100%"
    let transform = "none"
    let color = "initial"
    let margin = "var(--pageMargin) 0"

    if (hasMedia && AddColoredBorder && ColoredBorderWidth > 0) {
      border = `${ColoredBorderWidth}px solid ${HexColor}`
    }

    if (AddExtraPadding) {
      padding = "var(--pageMargin)"
    }

    if (BackgroundColor) {
      background = BackgroundColor
      width = "calc(100% + calc(2*var(--pageMargin))"
      transform = "translateX(calc(-1 *var(--pageMargin)))"
      margin = "0"
    }

    if (TextColor) {
      color = TextColor
    }

    return { border, padding, background, width, transform, color, margin }
  }

  const getClassName = () => {
    let className = "dagger-media-container-media"

    if (UseLandscapeAspectRatio) {
      className += " dagger-169"
    }

    return className
  }

  const handleShowVideo = (c) => {
    const curVid = document.getElementById(
      `video-${c.Media.data.attributes.hash}`
    )
    if (curVid) {
      curVid.pause()
    }

    setShowModal({
      id: c.id,
      src: c.Media.data.attributes.url,
      hash: c.Media.data.attributes.hash,
      UseLandscapeAspectRatio,
    })
  }

  const handleCloseVideo = () => {
    const curVid = document.getElementById(`video-${showModal.hash}`)

    if (curVid) {
      curVid.play()
    }

    setShowModal(false)
  }

  const getMediaItem = (c) => {
    const hasMedia = c.Media?.data

    if (!hasMedia) return null

    const isImage = c.Media.data.attributes.mime.indexOf("image") > -1
    let content
    if (isImage) {
      content = (
        <img
          src={`${c.Media.data.attributes.url}`}
          alt={c.Media.data.attributes.alternateText}
        />
      )
    } else {
      content = (
        <>
          <video
            src={`${c.Media.data.attributes.url}`}
            autoPlay
            muted
            playsInline
            loop
            id={`video-${c.Media.data.attributes.hash}`}
            onClick={() => handleShowVideo(c)}
          />
          <div className="video-play-icon">{playIcon}</div>
        </>
      )
    }

    if (c.UsePhoneContainer) {
      return (
        <PhoneHolderComponent
          avatar={c.PhoneContainerAvatar}
          handle={c.PhoneContainerHandle}
          content={content}
          shadowColor={HexColor}
        />
      )
    }

    return content
  }

  return (
    <div className="dagger-media-container" style={{ ...getStyles() }}>
      {showModal && (
        <VideoModalComponent {...showModal} onClose={handleCloseVideo} />
      )}
      {CaseStudyMedia.map((c) => {
        const width = Math.round(100 / CaseStudyMedia.length)
        return (
          <div
            className={getClassName()}
            style={{ flexBasis: `${width}%`, maxWidth: `${width}%` }}
            key={c.id}
          >
            {getMediaItem(c)}
            {c.Copy && (
              <div
                className="dagger-media-copy"
                dangerouslySetInnerHTML={{ __html: md.render(c.Copy) }}
              ></div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default CaseStudyMediaContainerComponent
