import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import API from "../../api"
import HeaderComponent from "../../components/header/header.component"
import FooterComponent from "../../components/footer/footer.component"
import "./case-study.page.css"
import CaseStudyHeroComponent from "../../components/case-study-hero/case-study-hero.component"
import CaseStudyProblemSolutionComponent from "../../components/case-study-problem-solution/case-study-problem-solution.component"
import CaseStudyMediaContainerComponent from "../../components/case-study-media-container/case-study-media-container.component"
import CaseStudyResultsComponent from "../../components/case-study-results/case-study-results.component"
import WorkSliderComponent from "../../components/work-slider/work-slider.component"
import ImageSliderComponent from "../../components/image-slider/image-slider.component"
import useScrollToTop from "../../utils/scrollToTop.util"
import { Helmet } from "react-helmet"
import FlexContainerComponent from "../../components/flex-container/flex-container.component"

const CaseStudy = () => {
  const { Slug } = useParams()
  const [content, setContent] = useState()
  const [moreWork, setMoreWork] = useState()
  useScrollToTop(Slug)

  useEffect(() => {
    if (!Slug) return

    const getPage = async () => {
      const res = await API.getContent(
        "case-studies",
        null,
        {
          "filters[Slug][$eq]": Slug,
          "populate[0]": "PageContent",
          "populate[1]": "Category",
          "populate[2]": "PageContent.Items",
          "populate[3]": "PageContent.Media",
          "populate[4]": "PageContent.ProblemSolutions",
          "populate[5]": "PageContent.CaseStudyMedia.Media",
          "populate[6]": "PageContent.Results",
          "populate[7]": "PageContent.CaseStudyMedia.PhoneContainerAvatar",
          "populate[8]": "MetaImage.Media",
          "populate[9]": "PageContent.Items.Media",
        },
        true
      )

      if (res) {
        setContent(res)
        setTimeout(
          () => document.dispatchEvent(new CustomEvent("EVENTS.STOP_LOADING")),
          250
        )
      } else {
        // Todo: Show error
      }
    }

    getPage()
  }, [Slug])

  const getContentForComponent = (comp) => {
    switch (comp.__component) {
      default: {
        console.error("UI Component not found: ", comp.__component)
        return null
      }
      case "ui-components.dagger-case-study-hero": {
        return (
          <CaseStudyHeroComponent
            key={comp.__component + comp.id}
            {...comp}
            HexColor={content.attributes.HexColor}
          />
        )
      }
      case "ui-components.dagger-case-study-problem-solution": {
        return (
          <CaseStudyProblemSolutionComponent
            key={comp.__component + comp.id}
            {...comp}
            HexColor={content.attributes.HexColor}
          />
        )
      }
      case "ui-components.dagger-case-study-results": {
        return (
          <CaseStudyResultsComponent
            key={comp.__component + comp.id}
            {...comp}
            HexColor={content.attributes.HexColor}
          />
        )
      }
      case "ui-components.dagger-case-study-media-container": {
        return (
          <CaseStudyMediaContainerComponent
            key={comp.__component + comp.id}
            {...comp}
            HexColor={content.attributes.HexColor}
          />
        )
      }
      case "ui-components.dagger-image-slider": {
        return (
          <ImageSliderComponent key={comp.__component + comp.id} {...comp} />
        )
      }
      case "ui-components.dagger-flex-container": {
        return (
          <FlexContainerComponent key={comp.__component + comp.id} {...comp} />
        )
      }
    }
  }

  // Get more work on load
  useEffect(() => {
    const handleGetMoreWork = async () => {
      try {
        const workItems = await API.getContent("case-studies", null, {
          "filters[Slug][$not]": Slug,
          "populate[0]": "HeroImage",
        })

        let more = null
        if (workItems.length > 0) {
          const items = workItems.map((w) => {
            return {
              CaseStudy: {
                data: w,
              },
              id: w.id,
            }
          })

          more = (
            <WorkSliderComponent
              Heading="More Work"
              Items={items}
              CtaButtonLabel="All Work"
            />
          )
        }

        setMoreWork(more)
      } catch (e) {
        // todo: show error
        console.error(e)
        return null
      }
    }

    if (content?.attributes.ShowMoreWork) {
      handleGetMoreWork()
    }
  }, [content, Slug])

  return (
    <div className="dagger-page">
      <Helmet>
        <title>{`DAGGER ${
          content?.attributes?.Title ? ` - ${content?.attributes?.Title}` : ""
        }`}</title>
        <meta
          property="og:description"
          content={content?.attributes.MetaDescription}
        />
        <meta property="og:site_name" content="DAGGER agency" />
        <meta property="og:title" content={content?.attributes.Title} />
        <meta property="og:type" content={content?.attributes.MetaPageType} />
        <meta
          property="og:image"
          content={
            content?.attributes.MetaImage?.data?.attributes?.url
              ? content.attributes.MetaImage.data.attributes.url
              : content?.attributes.MetaImageUrl
          }
        />
        <meta
          property="og:url"
          content={`${window.location.host}/study/${content?.attributes.Slug}`}
        />
      </Helmet>
      {content && <HeaderComponent HexColor={content.attributes.HexColor} />}
      <div className="dagger-case-study">
        <h1>{content?.attributes.Title}</h1>
        <h6>{content?.attributes.Category.data.attributes.Category}</h6>
        {content?.attributes.PageContent?.length > 0 &&
          content.attributes.PageContent.map((c) => getContentForComponent(c))}
        {content?.attributes.ShowMoreWork && moreWork}
      </div>
      <FooterComponent />
    </div>
  )
}

export default CaseStudy
